import { useEffect, useState } from 'react';
import { autorun } from 'mobx';

function usePickUpBranchProps(store) {
  const [state, setState] = useState({});
  useEffect(() => {
    return autorun(() => {
      setState({
        isBranchLoading: store.appStore.searchPage.pickupBranchStore.isLoading,
        isBranchSuccess: store.appStore.searchPage.pickupBranchStore.isSuccess,
        hasFailed: store.appStore.searchPage.pickupBranchStore.hasFailed,
        hasNoData: store.appStore.searchPage.pickupBranchStore.hasNoData,
        language: store.appStore.searchPage.pickupBranchStore.language,
        branchList: store.appStore.searchPage.pickupBranchStore.branchList,
        cityIdHashMap:
          store.appStore.searchPage.pickupBranchStore.cityIdHashMap,
        sortedCityIdHashMap:
          store.appStore.searchPage.pickupBranchStore.sortedCityIdHashMap,
        sortedDeliveryCityIdHashMap:
          store.appStore.searchPage.pickupBranchStore
            .sortedDeliveryCityIdHashMap,
        sortedPickUpCityIdHashMap:
          store.appStore.searchPage.pickupBranchStore.sortedPickUpCityIdHashMap,
        count: store.appStore.searchPage.pickupBranchStore.count,
        branchErrorData: store.appStore.searchPage.pickupBranchStore.errorData,
        setInitialState:
          store.appStore.searchPage.pickupBranchStore.setInitialState,
        searchType: store.appStore.searchPage.pickupBranchStore.searchType,
      });
    });
  }, [store]);

  return state;
}

export default usePickUpBranchProps;
