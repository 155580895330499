import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { makeStyles } from 'tss-react/mui';

import BRANCHES_TAB_INDEX from '@lumirental/lumi-web-shared/lib/constants/app/BRANCHES_TAB_INDEX';

import AirportBranchBlock from '@/blocks/AirportBranch';
import CityBlock from '@/blocks/City';
import CityBranchBlock from '@/blocks/CityBranch';
import BranchesTab from '@/components/Tabs/Branches';

import styles from '@/blocks/BranchTabs/BranchTabsBlock.style';

const useStyles = makeStyles()(styles);

export default function BranchTabsBlock() {
  // local state
  const [selectedTab, setSelectedTab] = useState(0);

  const { classes } = useStyles();
  const { t } = useTranslation();

  const tabs = [
    { id: 0, title: t('airport_branches_web') },
    { id: 1, title: t('city_SEO') },
    { id: 2, title: t('city_branches_web') },
  ];

  // return active class for selected tab
  const checkActiveTab = (tabIndex) => {
    return tabIndex === selectedTab ? 'active' : '';
  };

  const handleChangeTab = (evt, currenTab) => {
    setSelectedTab(currenTab);
  };

  return (
    <div className={classes.wrapper}>
      <BranchesTab
        tabs={tabs}
        selectedTab={selectedTab}
        handleChangeTab={handleChangeTab}
      />
      <div
        className={`${classes.tab} ${checkActiveTab(
          BRANCHES_TAB_INDEX.AIRPORT_BRANCH,
        )}`}
      >
        <AirportBranchBlock />
      </div>
      <div
        className={`${classes.tab} ${checkActiveTab(BRANCHES_TAB_INDEX.CITY)}`}
      >
        <CityBlock />
      </div>
      <div
        className={`${classes.tab} ${checkActiveTab(
          BRANCHES_TAB_INDEX.CITY_BRANCH,
        )}`}
      >
        <CityBranchBlock />
      </div>
    </div>
  );
}
