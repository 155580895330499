import React from 'react';
import _isEmpty from 'lodash/isEmpty';

import Divider from '@mui/material/Divider';

import { makeStyles } from 'tss-react/mui';

import RecentSearchList from '../Lists/RecentSearch';

import styles from './RecentSearch.style';

const useStyles = makeStyles()(styles);

export default function RecentSearch({ branches, handleSelectItem }) {
  const { classes } = useStyles();

  const hasNoRecentSearch = _isEmpty(branches);

  return (
    <div className={classes.wrapper}>
      {!hasNoRecentSearch && (
        <RecentSearchList
          branches={branches}
          handleSelectItem={handleSelectItem}
        />
      )}
      <Divider
        classes={{
          root: classes.divider,
        }}
      />
    </div>
  );
}
