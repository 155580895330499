import React from 'react';

import CarouselBlock from '@/blocks/Carousel';
import FleetCarouselBlock from '@/blocks/FleetCarousel';
import HeaderNavbarBlock from '@/blocks/HeaderNavbar';
import LocationMap from '@/blocks/LocationMap';
import LocationTableBlock from '@/blocks/LocationTable';
import OurServiceBlock from '@/blocks/OurService';
import OurStepBlock from '@/blocks/OurStep';
import SearchBlock from '@/blocks/Search';

const HomePageWidgets = {
  home_hero: {
    comp: (data, key, locale, store) => (
      <div key={key}>
        <SearchBlock store={store} />
        <HeaderNavbarBlock />
      </div>
    ),
  },
  home_whats_hot: {
    comp: (data, key, locale) => (
      <CarouselBlock data={data} key={key} language={locale} />
    ),
  },
  home_why_lumi: {
    comp: (data, key) => <OurServiceBlock data={data} key={key} />,
  },
  home_booking_steps: {
    comp: (data, key) => <OurStepBlock data={data} key={key} />,
  },
  home_car_groups: {
    comp: (data, key) => <FleetCarouselBlock key={key} />,
  },
  home_locations: {
    comp: (data, key) => (
      <div key={key}>
        <LocationMap />
        <LocationTableBlock showViewMore />
      </div>
    ),
  },
};

export default HomePageWidgets;
