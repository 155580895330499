import React from 'react';
import { useTranslation } from 'react-i18next';
import Image from 'next/legacy/image';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

import { makeStyles } from 'tss-react/mui';

import LANGUAGES from '@lumirental/lumi-web-shared/lib/constants/lang/LANGUAGES';

import ChipCustom from '@/components/Chip/ChipCustom';

import styles from '@/components/Cards/WhatsHot/Application/ApplicationCard.style';

const useStyles = makeStyles()(styles);

export default function ApplicationCard({ data, language }) {
  const { classes } = useStyles();
  const { t } = useTranslation();

  const isArabic = language === LANGUAGES.AR;
  const { backgroundImg, content } = data;

  const label = t('application');
  const baseAppImgSrc = '/images/png/';
  const androidAppImg = isArabic
    ? 'google-play-badge-ar.png'
    : 'google-play-badge-en.png';

  const iosAppImg = isArabic
    ? 'apple-store-badge-ar.png'
    : 'apple-store-badge-en.png';

  const androidAppImgSrc = `${baseAppImgSrc}${androidAppImg}`;
  const iosAppImgSrc = `${baseAppImgSrc}${iosAppImg}`;

  return (
    <Card className={classes.card}>
      {backgroundImg && (
        <div className={classes.bgWrap}>
          <Image
            alt="Download Lumi App"
            src={backgroundImg}
            layout="fill"
            objectFit="cover"
            quality={100}
          />
        </div>
      )}
      <CardContent className={classes.cardContent}>
        <div className={classes.contentInner}>
          <div className={classes.widget}>
            <div className={classes.offerContent}>
              <Typography className={classes.content}>{content}</Typography>
              <div className={classes.saveTimeMain}>
                <div className={classes.barCodeImgWrapper}>
                  <Image
                    src="/images/svg/lumi-app-barcode.svg"
                    alt="Lumi Barcode"
                    width={64}
                    height={64}
                    quality={100}
                  />
                </div>
                <div className={classes.appImgWrapper}>
                  <div className={classes.appImgItem}>
                    <a
                      target="_blank"
                      rel="nofollow noopener noreferrer"
                      href="https://apps.apple.com/ae/app/lumi-car-rental/id1489446283"
                      className={classes.appLink}
                    >
                      <Image
                        src={iosAppImgSrc}
                        alt="Lumi IOS App"
                        width={99}
                        height={34}
                        quality={100}
                      />
                    </a>
                  </div>
                  <div className={classes.appImgItem}>
                    <a
                      target="_blank"
                      rel="nofollow noopener noreferrer"
                      href="https://play.google.com/store/apps/details?id=com.seera.lumiapp "
                      className={classes.appLink}
                    >
                      <Image
                        src={androidAppImgSrc}
                        alt="Lumi Android App"
                        width={107}
                        height={33}
                        quality={100}
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {label && <ChipCustom size="small" label={label} />}
        </div>
      </CardContent>
    </Card>
  );
}
