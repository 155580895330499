import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Link from 'next/link';
import _get from 'lodash/get';

import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';

import { makeStyles } from 'tss-react/mui';

import LIST_THRESHOLD from '@lumirental/lumi-web-shared/lib/constants/app/LIST_THRESHOLD';
import SEO_PAGE_ROUTES from '@lumirental/lumi-web-shared/lib/constants/app/SEO_PAGE_ROUTES';
import { useLanguageContext } from '@lumirental/lumi-web-shared/lib/contexts/Language.context';
import { useGetCmsCities } from '@lumirental/lumi-web-shared/lib/tanStackQuery/cms/cities.queries';
import getLocaleRoute from '@lumirental/lumi-web-shared/lib/utils/getLocaleRoute';

import { onTapEvent } from '@/utils/gtm';

import BranchList from '@/components/Lists/Branch';

import styles from '@/blocks/City/CityBlock.style';

const useStyles = makeStyles()(styles);

export default function CityBlock() {
  const { classes } = useStyles();
  const { t } = useTranslation();

  const { language } = useLanguageContext();

  // read props for ssr

  const { isSuccess, isError, cityList } = useGetCmsCities({ language });

  // set initial state
  const [slicedCityList, setSlicedCityList] = useState(cityList);

  // derive
  const listCountThreshold = LIST_THRESHOLD.CITY;
  const showViewAllBtn = cityList.length !== slicedCityList.length;

  // runs on client side
  useEffect(() => {
    /**
     * on the client side decide if we need
     * to show full list or sliced one
     */
    setSlicedCityList(cityList.slice(0, listCountThreshold));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCityClick = (city) => {
    const citySlug = _get(city, 'slug', '');
    // send GTM event
    onTapEvent('Home', `${citySlug}_tapped`);
  };
  const sendGTMEvent = () => {
    // send GTM event
    onTapEvent('Home', `view_all_cities_tapped`);
  };

  return (
    <div className={classes.wrapper}>
      <Container>
        <Grid container>
          {isSuccess && (
            <>
              <Grid container className={classes.cityRow}>
                <BranchList
                  listData={slicedCityList}
                  hasNoData={isError}
                  hasFailed={isError}
                  branchRoute={SEO_PAGE_ROUTES.CITY}
                  language={language}
                  handleBranchClick={handleCityClick}
                />
              </Grid>
              {showViewAllBtn && (
                <div className={classes.btnWrapper}>
                  <Link
                    passHref
                    href={getLocaleRoute(language, SEO_PAGE_ROUTES.CITY)}
                    className={classes.allBranchesBtn}
                    legacyBehavior
                  >
                    <Button
                      className={classes.allBranchesBtn}
                      alt={t('view_all_web')}
                      title={t('view_all_web')}
                      onClick={sendGTMEvent}
                      target="_blank"
                    >
                      {t('view_all_web')}
                    </Button>
                  </Link>
                </div>
              )}
            </>
          )}
        </Grid>
      </Container>
    </div>
  );
}
