const styles = (theme) => ({
  card: {
    display: 'flex',
    background: 'transparent',
    boxShadow: 'none',
    position: 'relative',
    minHeight: 80,
    overflow: 'visible',
  },
  cardIcon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 8,
    minWidth: 40,
    height: 40,
    backgroundColor: theme.palette.common.blue400,
    borderRadius: 8,
    '& span': {
      color: theme.palette.common.white,
      fontSize: theme.typography.fontSize13,
      lineHeight: 1.5,
      fontWeight: theme.typography.fontWeightSemiBold,
    },
  },
  avatarIcon: {
    padding: '8px 4px',
    position: 'relative',
    backgroundColor: theme.palette.primary.main,
    zIndex: theme.zIndex.zIndex10,
    '&:before': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      content: 'attr(data-count)',
      position: 'absolute',
      width: 25,
      height: 25,
      top: -12,
      right: -12,
      color: theme.palette.common.white,
      fontSize: theme.typography.fontSize13,
      lineHeight: 1.5,
      fontWeight: theme.typography.fontWeightSemiBold,
      backgroundColor: theme.palette.common.blue400,
      borderRadius: '100%',
    },
    '& > img': {
      maxWidth: 32,
      width: '100%',
    },
  },

  cardContent: {
    padding: '0px 18px',
  },
  cardTitle: {
    color: theme.palette.common.blue400,
    fontSize: theme.typography.fontSize15,
    lineHeight: 1.5,
    fontWeight: theme.typography.fontWeightSemiBold,
    marginBottom: 4,
    minHeight: 40,
  },
  cardText: {
    color: theme.palette.text.primary,
    fontSize: theme.typography.fontSize13,
    lineHeight: 1.5,
    fontWeight: theme.typography.fontWeightMedium,
  },
  stepIcon: {
    position: 'absolute',
    left: -10,
    bottom: 0,
    width: 32,
    height: 32,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& > img': {
      width: '100%',
      maxWidth: 22,
    },
  },
  inArabic: {
    '& > img': {
      transform: 'rotate(180deg)',
    },
  },
});

export default styles;
