import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Link from 'next/link';
import _get from 'lodash/get';

import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';

import { makeStyles } from 'tss-react/mui';

import LIST_THRESHOLD from '@lumirental/lumi-web-shared/lib/constants/app/LIST_THRESHOLD';
import SEO_PAGE_ROUTES from '@lumirental/lumi-web-shared/lib/constants/app/SEO_PAGE_ROUTES';
import { useLanguageContext } from '@lumirental/lumi-web-shared/lib/contexts/Language.context';
import { useGetCmsCityBranches } from '@lumirental/lumi-web-shared/lib/tanStackQuery/cms/cityBranches.queries';
import getLocaleRoute from '@lumirental/lumi-web-shared/lib/utils/getLocaleRoute';

import { onTapEvent } from '@/utils/gtm';

import BranchList from '@/components/Lists/Branch';

import styles from '@/blocks/CityBranch/CityBranchBlock.style';

const useStyles = makeStyles()(styles);

export default function CityBranchBlock() {
  const { classes } = useStyles();
  const { t } = useTranslation();

  const { language } = useLanguageContext();

  const { isSuccess, isError, cityBranchList } = useGetCmsCityBranches({
    language,
  });

  // set initial state
  const [slicedCityBranchList, setSlicedCityBranchList] =
    useState(cityBranchList);

  // derive
  const listCountThreshold = LIST_THRESHOLD.CITY_BRANCH;
  const showViewAllBtn = cityBranchList.length !== slicedCityBranchList.length;

  // runs on client side
  useEffect(() => {
    /**
     * on the client side decide if we need
     * to show full list or sliced one
     */
    setSlicedCityBranchList(cityBranchList.slice(0, listCountThreshold));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCityBranchClick = (cityBranch) => {
    const cityBranchSlug = _get(cityBranch, 'slug', '');
    // send GTM event
    onTapEvent('Home', `${cityBranchSlug}_tapped`);
  };
  const sendGTMEvent = () => {
    // send GTM event
    onTapEvent('Home', `view_all_city_branch_tapped`);
  };

  return (
    <div className={classes.wrapper}>
      <Container>
        <Grid container>
          {isSuccess && (
            <>
              <Grid container className={classes.itemRow}>
                <BranchList
                  listData={slicedCityBranchList}
                  hasNoData={isError}
                  hasFailed={isError}
                  branchRoute={SEO_PAGE_ROUTES.CITY_BRANCH}
                  language={language}
                  handleBranchClick={handleCityBranchClick}
                />
              </Grid>
              {showViewAllBtn && (
                <div className={classes.btnWrapper}>
                  <Link
                    passHref
                    href={getLocaleRoute(language, SEO_PAGE_ROUTES.CITY_BRANCH)}
                    className={classes.allBranchesBtn}
                    legacyBehavior
                  >
                    <Button
                      className={classes.allBranchesBtn}
                      alt={t('view_all_web')}
                      title={t('view_all_web')}
                      onClick={sendGTMEvent}
                      target="_blank"
                    >
                      {t('view_all_web')}
                    </Button>
                  </Link>
                </div>
              )}
            </>
          )}
        </Grid>
      </Container>
    </div>
  );
}
