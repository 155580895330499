import React from 'react';
import { useTranslation } from 'react-i18next';
import _get from 'lodash/get';

import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { makeStyles } from 'tss-react/mui';

import OurStepCard from '@/components/Cards/OurStep';

import styles from '@/blocks/OurStep/OurStepBlock.style';

const useStyles = makeStyles()(styles);

export default function OurStepBlock({ data }) {
  const { classes } = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.wrapper} id="our_step">
      <Container>
        <Grid container>
          <Grid item sm={12}>
            <div className={classes.titleWrapper}>
              <Typography variant="h3" className={classes.title}>
                {t('Our_four_steps')}
              </Typography>
              <Typography variant="h3" className={classes.colorTitle}>
                {t('seamless_booking_experience')}
              </Typography>
            </div>
          </Grid>
          <Grid container className={classes.stepItemWrapper}>
            {data &&
              data.map((item, index) => {
                const id = _get(item, 'id', 0);
                const title = _get(item, 'title', '');
                const content = _get(item, 'content', '');
                const thumbnail = _get(item, 'thumbnail.url', '');
                const count = index + 1;
                return (
                  <Grid
                    key={id}
                    item
                    xs={12}
                    sm={12}
                    md={3}
                    className={classes.stepItem}
                  >
                    <OurStepCard
                      title={title}
                      content={content}
                      imgSrc={thumbnail}
                      count={count}
                    />
                  </Grid>
                );
              })}
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}
