import React from 'react';
import _get from 'lodash/get';

import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';

import { makeStyles } from 'tss-react/mui';

import styles from '@/components/Tabs/Branches/BranchesTab.style';

const useStyles = makeStyles()(styles);

export default function BranchesTab({ tabs, selectedTab, handleChangeTab }) {
  const { classes } = useStyles();

  return (
    <div>
      <div className={classes.tabBg}>
        <Container>
          <Grid container>
            <Grid item sm={12}>
              <Tabs
                classes={{
                  indicator: classes.indicator,
                }}
                value={selectedTab}
                onChange={handleChangeTab}
              >
                {tabs &&
                  tabs.map((tabItem) => {
                    const id = _get(tabItem, 'id', 0);
                    const label = _get(tabItem, 'title', '');
                    return (
                      <Tab
                        key={id}
                        label={label}
                        classes={{
                          root: classes.tab,
                        }}
                      />
                    );
                  })}
              </Tabs>
            </Grid>
          </Grid>
        </Container>
      </div>
    </div>
  );
}
