import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Image from 'next/legacy/image';
import { useRouter } from 'next/router';

import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { makeStyles } from 'tss-react/mui';

import STORAGE_KEYS from '@lumirental/lumi-web-sdk/dist/constants/app/STORAGE_KEYS';
import $sessionStorage from '@lumirental/lumi-web-sdk/dist/services/session.storage.service';
import SCREENS_NAME from '@lumirental/lumi-web-shared/lib/constants/app/SCREENS_NAME';
import useAB from '@lumirental/lumi-web-shared/lib/hooks/useAB';
import useSearchPageProps from '@lumirental/lumi-web-shared/lib/hooks/useSearchPageProps';
import searchNotApplicable from '@lumirental/lumi-web-shared/lib/utils/gtm/searchNotApplicable';
import searchTapped from '@lumirental/lumi-web-shared/lib/utils/gtm/searchTapped';
import isDropOffValid from '@lumirental/lumi-web-shared/lib/utils/isValidDropOff';
import navigateAndScrollToTop from '@lumirental/lumi-web-shared/lib/utils/lang/navigateAndScrollToTop';
import validateSearch from '@lumirental/lumi-web-shared/lib/utils/validateSearch';

import SearchWidget from '@/blocks/SearchWidget';
import InvalidSearchDialog from '@/components/Dialogs/InvalidSearch';

import styles from '@/blocks/Search/SearchBlock.style';

const useStyles = makeStyles()(styles);

export default function SearchBlock({ store, textInputRef = null }) {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const router = useRouter();

  const [openInvalidSearchDialog, setOpenInvalidSearchDialog] = useState(false);

  // read props for csr
  const {
    searchQueryString,
    setSearchDataUpdated,
    pickupBranch,
    dropoffBranch,
    pickupDate,
    dropoffDate,
    pickupTime,
    dropoffTime,
    isSameDay,
    resetAllFilters,
    activeTabIndex,
    isDropOffBranchSame,
    isDeliveryTabActive,
    minDaysOfDeliveryDropOff,
  } = useSearchPageProps(store);

  const isDeliveryFeatureFlag = useAB('free_car_delivery_tag');

  const screenName = SCREENS_NAME[router.pathname];

  const isInvalidDeliveryDropOff =
    isDeliveryFeatureFlag &&
    !isDropOffValid(pickupDate, dropoffDate, minDaysOfDeliveryDropOff);

  const isDeliveryTabActiveEnable =
    isDeliveryTabActive && isDeliveryFeatureFlag;

  const errorInfo =
    isInvalidDeliveryDropOff && isDeliveryTabActive
      ? t('car_delivery_error_xdays', {
          0: minDaysOfDeliveryDropOff,
        })
      : t('date_time_error');

  /**
   * Callback function for handling click
   * on Search button
   */
  const handleSearchClick = () => {
    // first verify if all the search data is valid
    const isSearchValid = validateSearch({
      pickupBranch,
      dropoffBranch,
      pickupDate,
      dropoffDate,
      pickupTime,
      dropoffTime,
      isSameDay,
      isDeliveryTabActive: isDeliveryTabActiveEnable,
      minDaysOfDeliveryDropOff,
    });

    if (isSearchValid) {
      // send GTM event when search button is tapped
      searchTapped(
        screenName,
        pickupBranch,
        dropoffBranch,
        pickupDate,
        dropoffDate,
        pickupTime,
        dropoffTime,
        activeTabIndex,
        isDropOffBranchSame,
      );

      if (isDeliveryFeatureFlag) {
        $sessionStorage(STORAGE_KEYS.ACTIVE_TAB, activeTabIndex);
      }

      // reset any previous filters
      resetAllFilters();

      // update store
      setSearchDataUpdated(true);

      // go to listing page
      const route = `fleet?${searchQueryString}`;
      navigateAndScrollToTop(router, route);
    } else {
      // show invalid search dialog
      handleOpenInvalidSearchDialog();

      // send GTM event when search is not applicable
      searchNotApplicable(screenName, pickupBranch, dropoffBranch);
    }
  };

  const handleOpenInvalidSearchDialog = () => {
    setOpenInvalidSearchDialog(true);
  };
  const handleCloseInvalidSearchDialog = () => {
    setOpenInvalidSearchDialog(false);
  };

  return (
    <>
      <div className={classes.mainWrapper} id="searchBlock">
        <div className={classes.bgWrap}>
          <Image
            alt="Lumi car rental"
            src="/images/png/search-back.jpg"
            layout="fill"
            quality={50}
            priority
          />
        </div>
        <div className={classes.wrapperInner}>
          <Container>
            <Grid container className={classes.container}>
              <div className={classes.titleWrapper}>
                <Typography variant="h1" className={classes.title}>
                  {t('car_rental_title')}
                </Typography>
              </div>
              <SearchWidget
                store={store}
                textInputRef={textInputRef}
                onSearchClick={handleSearchClick}
                isThemeChange
              />
            </Grid>
          </Container>
        </div>
      </div>
      {/**
       * This dialog will show up when user clicks on search button
       * And if the search data is invalid for the branch
       * And if drop off date is less then 30 days for car delivery
       * eg. branch is off on selected date
       */}
      <InvalidSearchDialog
        openDialog={openInvalidSearchDialog}
        closeDialog={handleCloseInvalidSearchDialog}
        errorInfo={errorInfo}
      />
    </>
  );
}
