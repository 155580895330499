const styles = (theme) => ({
  wrapper: {
    padding: '24px 0px',
  },
  titleWrapper: {
    marginBottom: 24,
  },
  title: {
    color: theme.palette.common.blue400,
    fontSize: theme.typography.fontSize28,
    lineHeight: 1.3,
    fontWeight: theme.typography.fontWeightSemiBold,
    marginBottom: 4,
  },
  subTitle: {
    color: theme.palette.common.grey400,
    fontSize: theme.typography.fontSize15,
    lineHeight: 1.5,
    marginBottom: 8,
  },
  ourServiceContentRow: {
    padding: 0,
    margin: '0 -12px',
    width: 'calc(100% + 24px)',
    '&:not(:last-child)': {
      marginBottom: 12,
    },
  },
  serviceItem: {
    padding: '16px 12px',
  },
});

export default styles;
