const styles = (theme) => ({
  dialog: {
    width: 510,
    borderRadius: 8,
    '& .MuiDialogContent-root': {
      paddingBottom: 16,
    },
  },
  noTitle: {
    minHeight: 250,
  },
  mainWrapper: {
    padding: '40px 48px !important',
    textAlign: 'center',
  },
  noTitleMainWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  titleWrapper: {
    display: 'inline-block',
    textAlign: 'center',
    marginBottom: 16,
  },
  title: {
    color: theme.palette.text.primary,
    fontSize: theme.typography.fontSize15,
    fontWeight: theme.typography.fontWeightSemiBold,
    marginBottom: 5,
  },
  titleInfo: {
    color: theme.palette.text.placeholder,
    fontSize: theme.typography.fontSize12,
  },
  paragraph: {
    color: theme.palette.text.primary,
    fontSize: theme.typography.fontSize14,
    wordBreak: 'break-word',
  },
  actionWrapper: {
    alignItems: 'center',
    justifyContent: 'center',
    borderTop: `1px solid ${theme.palette.common.grey20}`,
    padding: 0,
  },
  btnItem: {
    width: '100%',
    textAlign: 'center',
    '&:not(:first-of-type)': {
      margin: 0,
      borderLeft: `1px solid ${theme.palette.common.grey20}`,
    },
  },
  secondaryBtn: {
    fontSize: theme.typography.fontSize15,
    color: theme.palette.secondary.main,
    fontWeight: theme.typography.fontWeightRegular,
    textTransform: 'capitalize',
    padding: '20px 24px',
    width: '100%',
  },
  primaryBtn: {
    fontSize: theme.typography.fontSize15,
    color: theme.palette.secondary.main,
    textTransform: 'capitalize',
    padding: '20px 24px',
    width: '100%',
  },
});

export default styles;
