import React from 'react';

import Chip from '@mui/material/Chip';

import { makeStyles } from 'tss-react/mui';

import styles from '@/components/Chip/ChipCustom.style';

const useStyles = makeStyles()(styles);

export default function ChipCustom({ label, size = 'small' }) {
  const { classes } = useStyles();

  return <Chip size={size} label={label} className={classes.chip} />;
}
