import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';

import { makeStyles } from 'tss-react/mui';

import styles from './DifferentCityReturnDialog.style';

const useStyles = makeStyles()(styles);

function DifferentCityReturnDialog({ open, onClose, onProceed }) {
  const { classes } = useStyles();
  const { t } = useTranslation();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      classes={{
        paper: classes.dialog,
      }}
      data-testid="searchDifferentCityDialog"
    >
      <div className={classes.mainWrapper}>
        <div className={classes.locationBadge}>
          <img
            src="/images/svg/diff-location-icon.svg"
            alt="Return to a different city"
            className={classes.locationIcon}
          />
        </div>

        <div className={classes.contentWrapper}>
          <Typography variant="h4" className={classes.title}>
            {t('returning_to_a_different_city')}
          </Typography>
          <Typography variant="body2" className={classes.textInfo}>
            {t('transport_cost')}
          </Typography>
        </div>

        <div className={classes.actionWrapper}>
          <Button
            variant="contained"
            className={classes.proceedBtn}
            onClick={onProceed}
            data-testid="searchDifferentCityProceedBtn"
          >
            {t('proceed')}
          </Button>
          <Button
            className={classes.chooseDiffBtn}
            variant="text"
            color="secondary"
            onClick={onClose}
          >
            {t('choose_different_return_location')}
          </Button>
        </div>
      </div>
    </Dialog>
  );
}

DifferentCityReturnDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onProceed: PropTypes.func.isRequired,
};

export default DifferentCityReturnDialog;
