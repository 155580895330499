const styles = (theme) => ({
  mainWrapper: {
    position: 'relative',
    backgroundColor: theme.palette.common.blue400,
    '&:after': {
      content: '""',
      position: 'absolute',
      left: 0,
      top: 0,
      width: '100%',
      height: '100%',
      backgroundColor: theme.palette.common.grey400,
      opacity: '0.35',
    },
  },
  bgWrap: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  wrapperInner: {
    width: '100%',
    padding: '40px 0px',
  },
  container: {
    position: 'relative',
    zIndex: theme.zIndex.zIndex100,
  },
  titleWrapper: {
    width: '100%',
    marginBottom: 40,
  },
  title: {
    color: theme.palette.common.white,
    fontSize: theme.typography.fontSize38,
    lineHeight: 1.3,
    fontWeight: theme.typography.fontWeightBold,
  },
});

export default styles;
