import React, { useState } from 'react';
import { Detector } from 'react-detect-offline';
import { useTranslation } from 'react-i18next';
import { useRouter } from 'next/router';

import Snackbar from '@mui/material/Snackbar';

import useSideMenuProps from '@lumirental/lumi-web-shared/lib/hooks/useSideMenuProps';
import {
  Hydrate,
  QueryClient,
  QueryClientProvider,
  ReactQueryDevtools,
} from '@lumirental/lumi-web-shared/lib/tanStackQuery/config';

import DownloadAppBannerBlock from '@/blocks/DownloadAppBanner';
import Footer from '@/blocks/Footer';
import Header from '@/blocks/Header';

const nonBookingFlowRoutes = [
  '/',
  '/vehicles',
  '/services',
  '/locations',
  '/faq',
  '/terms',
  '/privacy',
  '/offers',
];

export default function LayoutContainer({ store, children, ...pageProps }) {
  const [queryClient] = React.useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            refetchOnWindowFocus: false, // default: true
            refetchInterval: false,
            retry: 1,
          },
        },
      }),
  );

  const [showSnackBar, setShowSnackBar] = useState(false);
  const [message, setMessage] = useState('');

  const router = useRouter();
  const { t } = useTranslation();

  // reading prop from config store
  const { isLoggedIn, setIsBookingFlow, logout } = useSideMenuProps(store);

  const currentPath = router.route;
  const isNonBookingFlow = nonBookingFlowRoutes.indexOf(currentPath) !== -1;

  const handleChange = (online) => {
    setShowSnackBar(true);
    setMessage(online ? t('online') : t('offline'));
  };

  return (
    <QueryClientProvider client={queryClient}>
      <Hydrate state={pageProps?.dehydratedState}>
        <>
          <Header
            isLoggedIn={isLoggedIn}
            setIsBookingFlow={setIsBookingFlow}
            logout={logout}
          />
          {children}
          {isNonBookingFlow && <DownloadAppBannerBlock />}
          <Footer isLoggedIn={isLoggedIn} setIsBookingFlow={setIsBookingFlow} />
          <Detector
            onChange={handleChange}
            render={() => (
              <Snackbar
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                open={showSnackBar}
                autoHideDuration={3000}
                message={message}
                onClose={() => setShowSnackBar(false)}
                data-testid="layoutSnackbar"
              />
            )}
          />
        </>
      </Hydrate>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
}
