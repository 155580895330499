import { useEffect, useState } from 'react';
import { autorun } from 'mobx';

function usePickUpLocationProps(store) {
  const [state, setState] = useState({});
  useEffect(() => {
    return autorun(() => {
      setState({
        isLocationLoading:
          store.appStore.searchPage.pickupLocationStore.isLoading,
        isLocationSuccess:
          store.appStore.searchPage.pickupLocationStore.isSuccess,
        hasLocationFailed:
          store.appStore.searchPage.pickupLocationStore.hasFailed,
        lat: store.appStore.searchPage.pickupLocationStore.lat,
        long: store.appStore.searchPage.pickupLocationStore.long,
        locationErrorData:
          store.appStore.searchPage.pickupLocationStore.errorData,
        clearLocationProps:
          store.appStore.searchPage.pickupLocationStore.clearLocationProps,
      });
    });
  }, [store]);

  return state;
}

export default usePickUpLocationProps;
