import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import addDays from 'date-fns/addDays';
import _get from 'lodash/get';

import Button from '@mui/material/Button';
import ButtonBase from '@mui/material/ButtonBase';
import Grid from '@mui/material/Grid';
import Snackbar from '@mui/material/Snackbar';
import Typography from '@mui/material/Typography';

import { makeStyles } from 'tss-react/mui';

import STORAGE_KEYS from '@lumirental/lumi-web-sdk/dist/constants/app/STORAGE_KEYS';
import $sessionStorage from '@lumirental/lumi-web-sdk/dist/services/session.storage.service';
import TAB_INDEX from '@lumirental/lumi-web-shared/lib/constants/form/TAB_INDEX';
import getNextWorkingDay from '@lumirental/lumi-web-shared/lib/utils/date/getNextWorkingDay';

import styles from './FindBranchTab.style';

const useStyles = makeStyles()(styles);

export default function FindBranchTab({
  activeTabIndex,
  setActiveTabIndex,
  setActiveBranchTab,
  pickUpBranchCount,
  deliveryBranchCount,
  pickupDate,
  dropoffBranch,
  setDropOffDate,
  minDaysOfDeliveryDropOff,
  onTabClick,
  isPickUpBranchSelected,
  showCloseBtn = false,
  handleCloseSearch = () => {},
}) {
  // state for snackbar message to show tab is changed
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const { classes } = useStyles();
  const { t } = useTranslation();

  const storageActiveTab = $sessionStorage(STORAGE_KEYS.ACTIVE_TAB);

  const pickUpBranchText = `${pickUpBranchCount} ${t('branches', {
    count: pickUpBranchCount,
  })}`;
  const deliveryBranchText = `${deliveryBranchCount} ${t('branches', {
    count: deliveryBranchCount,
  })}`;

  const closeBtnDisabled = !isPickUpBranchSelected;

  /**
   * call effect update activeTabIndex when session storage is set.
   */
  useEffect(() => {
    const branchId = _get(dropoffBranch, 'id', 0); // get dropoff branch id
    if (setActiveTabIndex && storageActiveTab && branchId) {
      setActiveTabIndex(storageActiveTab);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setActiveTabIndex]);

  const checkActiveTab = (tabIndex) => {
    return tabIndex === activeTabIndex ? 'active' : '';
  };

  const handleTabClick = (evt, tabIndex) => {
    let newDropOffDate = null;
    if (tabIndex === TAB_INDEX.FIRST) {
      newDropOffDate = addDays(pickupDate, 1); // add 1 day
    } else {
      newDropOffDate = addDays(pickupDate, minDaysOfDeliveryDropOff); // add no. of days for delivery branch
    }

    const offDays = _get(dropoffBranch, 'offDays', []); // get dropoff off days
    const nextWorkingDay = getNextWorkingDay(newDropOffDate, offDays);

    setDropOffDate(nextWorkingDay); // update global store
    setActiveBranchTab(tabIndex); // update global store
    setOpenSnackBar(true);
    onTabClick && onTabClick();
  };

  const handleSnackBarClose = (evt, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackBar(false);
  };

  return (
    <>
      <Grid container className={classes.tabWrapper}>
        <div className={classes.tabItem}>
          <ButtonBase
            className={`${classes.tabContent} ${checkActiveTab(
              TAB_INDEX.FIRST,
            )}`}
            onClick={(evt) => handleTabClick(evt, TAB_INDEX.FIRST)}
            data-testid="searchPickUpTab"
          >
            <Typography variant="subtitle1" className={classes.tabTitle}>
              {t('pickup')}
            </Typography>
            <Typography variant="subtitle2" className={classes.tabSubtitle}>
              {pickUpBranchText}
            </Typography>
          </ButtonBase>
        </div>
        <div className={classes.tabItem}>
          <ButtonBase
            className={`${classes.tabContent} ${checkActiveTab(
              TAB_INDEX.SECOND,
            )}`}
            onClick={(evt) => handleTabClick(evt, TAB_INDEX.SECOND)}
            data-testid="searchDeliveryTab"
          >
            <Typography variant="subtitle1" className={classes.tabTitle}>
              {t('delivery')}
            </Typography>
            <Typography variant="subtitle2" className={classes.tabSubtitle}>
              {deliveryBranchText}
            </Typography>
          </ButtonBase>
        </div>
        {showCloseBtn && (
          <div className={classes.closeBtnWrapper}>
            <Button
              variant="text"
              className={classes.closeBtn}
              onClick={handleCloseSearch}
              disabled={closeBtnDisabled}
              data-testid="closeButton"
            >
              {t('close')}
            </Button>
          </div>
        )}
      </Grid>
      <Snackbar
        classes={{
          root: classes.snackBarRoot,
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={openSnackBar}
        onClose={handleSnackBarClose}
        autoHideDuration={1000}
        message={t('dropoff_date_updated')}
        data-testid="searchFindTabSnackbarError"
      />
    </>
  );
}
