import React from 'react';
import { useRouter } from 'next/router';
import _get from 'lodash/get';

import SCREENS_NAME from '@lumirental/lumi-web-shared/lib/constants/app/SCREENS_NAME';
import { useLanguageContext } from '@lumirental/lumi-web-shared/lib/contexts/Language.context';
import useSideMenuProps from '@lumirental/lumi-web-shared/lib/hooks/useSideMenuProps';
import { useGetCmsHome } from '@lumirental/lumi-web-shared/lib/tanStackQuery/cms/home.queries';

import { MarketingDialogWrapper } from '@lumirental/lumi-web-components-ui';

import BranchTabsBlock from '@/blocks/BranchTabs';
import HomePageWidgets from '@/widgets/HomePageWidgets';

export default function SearchContainer({ store }) {
  const router = useRouter();

  const { language } = useLanguageContext();

  const { sections } = useGetCmsHome({ language });

  // reading prop from config store
  const { isLoggedIn } = useSideMenuProps(store);

  const screenName = SCREENS_NAME[router.pathname];

  return (
    <>
      {sections?.map((cmsItem, index) => {
        const widget = _get(HomePageWidgets, `${cmsItem.mappingKey}`, null);
        if (widget) {
          const compFn = widget.comp;
          return compFn(cmsItem.data, index, language, store);
        }
        return null;
      })}
      <BranchTabsBlock />
      <MarketingDialogWrapper isLoggedIn={isLoggedIn} screenName={screenName} />
    </>
  );
}
