const styles = (theme) => ({
  chip: {
    height: 19,
    padding: '0 10px',
    backgroundColor: theme.palette.common.blue400,
    '& > .MuiChip-label': {
      padding: 0,
      color: theme.palette.common.white,
      fontSize: theme.typography.fontSize13,
      lineHeight: 1.5,
    },
  },
});

export default styles;
