import React from 'react';
import { useTranslation } from 'react-i18next';
import Link from 'next/link';
import _get from 'lodash/get';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { makeStyles } from 'tss-react/mui';

import getLocaleRoute from '@lumirental/lumi-web-shared/lib/utils/getLocaleRoute';

import styles from '@/components/Lists/Branch/BranchList.style';

const useStyles = makeStyles()(styles);

export default function BranchList({
  listData,
  hasNoData,
  hasFailed,
  language,
  branchRoute,
  handleBranchClick,
}) {
  const { classes } = useStyles();
  const { t } = useTranslation();

  if (hasNoData || hasFailed) {
    return (
      <Typography variant="h6" className={classes.noOfferMsg}>
        {t('nothing_found')}
      </Typography>
    );
  }
  return (
    <>
      {listData &&
        listData.map((branch) => {
          const id = _get(branch, 'branchId', 0);
          const branchName = _get(branch, 'name', '');
          const branchSlug = _get(branch, 'slug', '');
          const branchPath = `${branchRoute}/${branchSlug}`;
          return (
            <Grid
              key={id}
              item
              sm={6}
              md={4}
              lg={3}
              className={classes.listItem}
            >
              <Link
                passHref
                href={getLocaleRoute(language, branchPath)}
                target="_blank"
              >
                <Typography
                  alt={branchName}
                  title={branchName}
                  className={classes.label}
                  onClick={() => handleBranchClick(branch)}
                >
                  {branchName}
                </Typography>
              </Link>
            </Grid>
          );
        })}
    </>
  );
}
