/**
 * Main entry point
 */

import React from 'react';
import { I18nextProvider } from 'react-i18next';
import App from 'next/app';
import Head from 'next/head';
import { withRouter } from 'next/router';
import {
  AnimateLayoutFeature,
  AnimationFeature,
  ExitFeature,
  GesturesFeature,
  MotionConfig,
} from 'framer-motion';
import i18next from 'i18next';
import _get from 'lodash/get';
import { autorun } from 'mobx';

import { CacheProvider } from '@emotion/react';
import { ThemeProvider } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';

import $http from '@lumirental/lumi-web-sdk/dist/services/http.service';
import isServer from '@lumirental/lumi-web-sdk/dist/utils/ssr/isServer';
import DEFAULT_DIR from '@lumirental/lumi-web-shared/lib/constants/lang/DEFAULT_DIR';
import DEFAULT_LANG from '@lumirental/lumi-web-shared/lib/constants/lang/DEFAULT_LANG';
import { ABTestingProvider } from '@lumirental/lumi-web-shared/lib/contexts/abTesting.context';
import ConfigProvider from '@lumirental/lumi-web-shared/lib/contexts/Config.context';
import LanguageProvider from '@lumirental/lumi-web-shared/lib/contexts/Language.context';
import {
  createGrowthBook,
  GrowthBookWrapper,
} from '@lumirental/lumi-web-shared/lib/utils/growthBook';
import initSDKStore from '@lumirental/lumi-web-shared/lib/utils/initSDKStore';

import { getTheme } from '@/themes';
import createEmotionCache from '@/utils/createEmotionCache';
import { initI18n } from '@/utils/i18n';

import LayoutContainer from '@/containers/LayoutContainer';

let disposer = null;
// comment
class MyApp extends App {
  /**
   * Runs on server side & client side
   */
  static async getInitialProps(appContext) {
    const start = Date.now();
    const { res } = appContext.ctx;
    const language = _get(res, 'locals.language', DEFAULT_LANG);
    const dir = _get(res, 'locals.dir', DEFAULT_DIR);
    const translations = _get(res, 'locals.locale', {});
    const isLoggedIn = _get(res, 'locals.isLoggedIn', false);
    const sessionToken = _get(res, 'locals.sessionToken', null);
    const checkoutPayKey = _get(res, 'locals.checkoutPayKey', '');
    const reCaptchaSiteKey = _get(res, 'locals.reCaptchaSiteKey', '');
    const iosAppId = _get(res, 'locals.iosAppId', '');
    const appEnv = _get(res, 'locals.appEnv', 'staging');
    const appVersion = `v${_get(res, 'locals.version', '')}`;

    const initialData = {
      config: {
        data: {
          locale: language,
          direction: dir,
          currency: 'SAR',
          checkoutPayKey,
          reCaptchaSiteKey,
          iosAppId,
          appEnv,
          appVersion,
        },
      },
      accountPage: {
        data: {
          isLoggedIn,
          sessionToken,
        },
      },
    };
    const store = initSDKStore(initialData);
    console.log(Date.now() - start, 'ms to initSDKStore');
    // add app version & platform header for server side
    $http.addHeaders({
      'x-app-version': _get(store, 'appStore.config.appVersion', ''),
      'x-platform': 'desktop',
    });

    // Make stores available to page's `getInitialProps`
    appContext.ctx.store = store;

    // Call "super" to run page's `getInitialProps`
    const appProps = await App.getInitialProps(appContext);
    console.log(Date.now() - start, 'ms to getInitialProps');
    let gbData;
    try {
      if (isServer()) {
        const gb = await createGrowthBook(store.appStore.config.data.appEnv);
        console.log(Date.now() - start, 'ms to createGrowthBook');
        gbData = gb.getDecryptedPayload();
        // Cleanup your GrowthBook instance
        gb.destroy();
      }
    } catch (error) {
      console.error('Growth book Error', error);
    }

    return {
      ...appProps,
      initialState: store,
      appEnv,
      appVersion,
      language,
      dir,
      translations,
      gbData,
    };
  }

  /**
   * Runs on server side & client side
   */
  constructor(props) {
    super(props);
    this.state = {
      abKeys: [],
    };
    // this.workerRef = createRef();
    const {
      language,
      appEnv,
      appVersion,
      dir,
      initialState,
      translations,
      router,
      gbData,
    } = props;
    this.store = isServer()
      ? initialState
      : initSDKStore(initialState.appStore);

    // console.log('# app constructor: ', initialState.appStore);
    initI18n(language, translations);
    this.language = language;
    this.appEnv = appEnv;
    this.appVersion = appVersion;
    this.dir = dir;
    this.i18n = i18next;
    this.theme = getTheme(dir);
    this.clientSideEmotionCache = createEmotionCache(dir);
    this.router = router;
    this.gbData = gbData;
  }

  /**
   * Runs on client side
   */
  componentDidMount() {
    const handleError = (error) => {
      console.error('Unhandled Error:', error);
      // Optional: Send to error tracking service
      // sendToErrorTracking(error);
    };

    const handleRejection = (event) => {
      console.error('Unhandled Promise Rejection:', event.reason);
      // Optional: Send to error tracking service
      // sendToErrorTracking(event.reason);
    };

    process.on('uncaughtException', handleError);
    process.on('unhandledRejection', handleRejection);

    // Add specific SIGTERM handler
    process.on('SIGTERM', () => {
      console.log('Received SIGTERM signal');
      // Perform cleanup if needed
      process.exit(0);
    });

    disposer = autorun(() => {
      // console.log('\n** _app autorun **');
      this.setState({
        abKeyMap: this.store.appStore.abTesting.abKeyMap,
      });
    });

    const isStagingOrProd =
      this.store.appStore.config.data.appEnv === 'production' ||
      this.store.appStore.config.data.appEnv === 'staging';

    // make store available in console for local dev env
    if (!isStagingOrProd) {
      global.store = this.store;
    }
    // disable console logs in production
    if (isStagingOrProd) {
      console.log = () => {};
    }
    // add app version & platform header for client side
    $http.addHeaders({
      'x-app-version': _get(this.store, 'appStore.config.appVersion', ''),
      'x-platform': 'desktop',
    });
    // remove auth headers if any
    $http.removeHeaders(['Authorization']);

    // web worker to delete stale cache data
    // this.workerRef.current = new Worker('../worker', { type: 'module' });
    // this.workerRef.current.postMessage('');
    // this.workerRef.current.onmessage = (evt) => {
    //   console.log('Stale cache removed: ', evt.data);
    // };
  }

  /**
   * Runs on client side
   */
  componentWillUnmount() {
    if (disposer) {
      disposer();
    }
  }

  /**
   * Runs on server side & client side
   */
  render() {
    const { store, props, state, i18n, gbData } = this; // language, dir,
    const {
      Component,
      emotionCache = this.clientSideEmotionCache,
      pageProps,
    } = props;

    return (
      <>
        <CacheProvider value={emotionCache}>
          <Head>
            <meta
              name="viewport"
              content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
            />
          </Head>
          <ConfigProvider appEnv={this.appEnv} appVersion={this.appVersion}>
            <LanguageProvider language={this.language}>
              <ThemeProvider theme={this.theme}>
                <CssBaseline />

                <MotionConfig
                  features={[
                    GesturesFeature,
                    AnimationFeature,
                    AnimateLayoutFeature,
                    ExitFeature,
                  ]}
                >
                  <I18nextProvider i18n={i18n}>
                    <GrowthBookWrapper payload={gbData}>
                      <LayoutContainer {...pageProps} store={store}>
                        <ABTestingProvider value={state.abKeyMap}>
                          <Component {...pageProps} store={store} />
                        </ABTestingProvider>
                      </LayoutContainer>
                    </GrowthBookWrapper>
                  </I18nextProvider>
                </MotionConfig>
              </ThemeProvider>
            </LanguageProvider>
          </ConfigProvider>
        </CacheProvider>
      </>
    );
  }
}

export default withRouter(MyApp);
