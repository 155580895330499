import React from 'react';
import { useTranslation } from 'react-i18next';
import Slider from 'react-slick';
import _get from 'lodash/get';

import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';

import { makeStyles } from 'tss-react/mui';

import { useLanguageContext } from '@lumirental/lumi-web-shared/lib/contexts/Language.context';
import { useGetGroupTariff } from '@lumirental/lumi-web-shared/lib/tanStackQuery/groupTariff.queries';

import { manageSmoothScrolling } from '@/utils';
import { onTapEvent } from '@/utils/gtm';

import FleetCarouselCard from '@/components/Cards/FleetCarousel';
import LeftArrow from '@/components/SlickArrows/LeftArrow';
import RightArrow from '@/components/SlickArrows/RightArrow';

import styles from '@/blocks/FleetCarousel/FleetCarouselBlock.style';

const useStyles = makeStyles()(styles);

export default function FleetCarouselBlock() {
  const { classes } = useStyles();
  const { t } = useTranslation();

  const { language, isArabic } = useLanguageContext();

  const { tariffList } = useGetGroupTariff();

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    rtl: isArabic,
    nextArrow: <RightArrow aria-label="Next" language={language} />,
    prevArrow: <LeftArrow aria-label="Previous" language={language} />,
    responsive: [
      {
        breakpoint: 1260,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const handleClick = () => {
    // scroll to search widget
    manageSmoothScrolling('#searchBlock');

    // send GA event
    onTapEvent('home_fleet_carousel', 'fleet_crousel_cta_tapped');
  };

  return (
    <div className={classes.wrapper}>
      <Container>
        <Grid container className={classes.sliderWrapper}>
          <Slider {...settings}>
            {tariffList.map((tariffItem, index) => {
              const fleetClass = _get(tariffItem, 'class', '');
              const dailyPrice = _get(tariffItem, 'dailyPrice', '');
              const thumbnail = _get(tariffItem, 'thumbnail', '');
              const id = _get(tariffItem, `${index}_${fleetClass}`, 0);
              return (
                <Grid key={id} item className={classes.stepItem}>
                  <FleetCarouselCard
                    imgSrc={thumbnail}
                    fleetClass={fleetClass}
                    price={dailyPrice}
                  />
                </Grid>
              );
            })}
          </Slider>

          <Grid item sm={12}>
            <div className={classes.btnWrapper}>
              <Button
                fullWidth
                variant="contained"
                className={classes.searchBtn}
                data-testid="fleetSearchButton"
                onClick={handleClick}
              >
                {t('search')}
              </Button>
            </div>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}
