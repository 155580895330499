const styles = (theme) => ({
  card: {
    width: '100%',
    backgroundColor: theme.palette.primary.main,
    borderRadius: 8,
    minHeight: 220,
    direction: 'ltr',
  },
  cardContent: {
    padding: 16,
    position: 'relative',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    minHeight: 220,
    '&:last-child': {
      paddingBottom: 16,
    },
  },

  contentInner: {
    padding: 8,
    position: 'relative',
    zIndex: theme.zIndex.zIndex100,
  },
  widget: {
    padding: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    minHeight: 172,
  },
  content: {
    color: theme.palette.common.blue400,
    fontSize: theme.typography.fontSize13,
    lineHeight: 1.5,
    fontWeight: theme.typography.fontWeightSemiBold,
    marginBottom: 18,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'normal',
    display: '-webkit-box !important',
    lineClamp: 4,
    boxOrient: 'vertical',
  },
  chipWrapper: {
    textAlign: 'right',
    marginBottom: 16,
  },
  dateWrapper: {},
  date: {
    color: theme.palette.text.primary,
    fontSize: theme.typography.fontSize12,
    lineHeight: 1.5,
  },
  commentWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  commentItem: {
    maxWidth: '50%',
  },
  commentContent: {
    display: 'flex',
    alignItems: 'center',
  },
  author: {
    color: theme.palette.text.primary,
    fontSize: theme.typography.fontSize13,
    lineHeight: 1.5,
  },
  commentImg: {
    minWidth: 32,
    marginRight: 16,
  },
  reviewImg: {
    maxWidth: 32,
    width: '100%',
    marginRight: 8,
  },
  reviewText: {
    color: theme.palette.text.primary,
    fontSize: theme.typography.fontSize13,
    lineHeight: 1.5,
  },
  saveTimeMain: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingTop: 8,
  },
});

export default styles;
