/**
 * All GTM events for desktop app are here
 * There are LWEB specific events
 */

import pushDataLayer from '@lumirental/lumi-web-sdk/dist/utils/gtm/pushDataLayer';

/**
 * Event triggered by tapping on some link
 * @param {string} screenName
 * @param {string} eventName
 */
export function onTapEvent(screenName, eventName) {
  const eventData = {
    event: eventName,
    screen_name: screenName,
  };
  console.log(`** gtm event ${eventName}: `, eventData);
  pushDataLayer(eventData);
}
