import React from 'react';
import { useTranslation } from 'react-i18next';
import Image from 'next/legacy/image';

import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { makeStyles } from 'tss-react/mui';

import { useLanguageContext } from '@lumirental/lumi-web-shared/lib/contexts/Language.context';
import { getDesktopCDNLink } from '@lumirental/lumi-web-shared/lib/utils/cdnLinks';

import styles from '@/blocks/LocationMap/LocationMap.style';

const useStyles = makeStyles()(styles);

export default function LocationMap() {
  const { classes } = useStyles();
  const { t } = useTranslation();

  const { isArabic } = useLanguageContext();

  // to-do: remove comment.

  const mapSrc = isArabic
    ? '/images/png/location-map-image-ar.png'
    : '/images/png/location-map-image-en.png';

  return (
    <div className={classes.wrapper}>
      <Container>
        <Grid container>
          <Grid item sm={12}>
            <div className={classes.titleWrapper}>
              <Typography variant="h4" className={classes.title}>
                {t('Our_locations_home_screen')}
              </Typography>
              <Typography
                className={classes.subTitle}
                dangerouslySetInnerHTML={{
                  __html: t('We_are_at_20'),
                }}
              />
            </div>
          </Grid>
          <div className={classes.mapWrapper}>
            <Image
              className={classes.mapImg}
              alt="Lumi branch locations"
              src={getDesktopCDNLink(mapSrc)}
              layout="fill"
              objectFit="cover"
              quality={100}
            />
          </div>
        </Grid>
      </Container>
    </div>
  );
}
