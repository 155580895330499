const styles = (theme) => ({
  mainWrapper: {
    backgroundColor: theme.palette.common.white,
  },
  innerWrapper: {
    position: 'relative',
    overflow: 'hidden',
  },
  container: {
    alignItems: 'flex-end',
    position: 'relative',
    zIndex: theme.zIndex.zIndex10,
  },
  downloadAppImageWrapper: {
    '& > div': {
      overflow: 'visible !important',
      marginLeft: '-60px !important',
      '@media(max-width: 959px)': {
        marginLeft: '0px !important',
      },
    },
    '@media(max-width: 959px)': {
      textAlign: 'center',
    },
  },
  downloadAppImage: {
    marginBottom: '-8px !important',
  },
  saveTimeSection: {
    paddingTop: 50,
    paddingLeft: theme.direction === 'rtl' ? 50 : 20,
    '@media(max-width: 959px)': {
      paddingTop: 20,
      paddingLeft: 0,
    },
  },
  saveTimeHeader: {
    marginBottom: 24,
  },
  saveTimeTitle: {
    color: theme.palette.common.blue400,
    fontSize: theme.typography.fontSize28,
    lineHeight: 1.3,
    fontWeight: theme.typography.fontWeightSemiBold,
    marginBottom: 8,
  },
  saveTimeSubtitle: {
    color: theme.palette.common.grey400,
    fontSize: theme.typography.fontSize15,
    lineHeight: 1.5,
    marginBottom: 12,
  },
  saveTimeMain: {
    display: 'flex',
    marginBottom: 50,
    '@media(max-width: 959px)': {
      marginBottom: 20,
    },
  },
  saveTimeBarcodeWrapper: {
    width: '100%',
    marginRight: 32,
    maxWidth: 138,
  },
  saveTimeAppWrapper: {
    width: '100%',
    padding: '4px 0',
  },
  saveTimeAppItem: {
    display: 'flex',
    alignItems: 'center',
    '&:not(:last-child)': {
      marginBottom: 34,
    },
  },
  appItemLeft: {
    width: '100%',
    maxWidth: 140,
    marginRight: 8,
  },
  appLink: {
    '& > img': {
      width: '100%',
    },
  },
  appItemRight: {
    width: '100%',
  },
  appItemRatingSection: {
    marginBottom: 4,
  },
  ratingPoint: {
    color: theme.palette.common.white,
    fontSize: theme.typography.fontSize15,
    lineHeight: 1.3,
    fontWeight: theme.typography.fontWeightSemiBold,
    marginRight: 8,
  },
  ratingText: {
    paddingLeft: 8,
    color: theme.palette.common.white,
    fontSize: theme.typography.fontSize13,
    lineHeight: 1.6,
    borderLeft: `1px solid ${theme.palette.common.white}`,
  },
  appItemStartSection: {
    color: theme.palette.common.white,
  },
});

export default styles;
