import DIR from '@lumirental/lumi-web-shared/lib/constants/lang/DIR';
import FONT_FAMILY from '@lumirental/lumi-web-shared/lib/constants/lang/FONT_FAMILY';
import commonOverride from '@lumirental/lumi-web-theme/lib/lumi.overrides.common';
import palette from '@lumirental/lumi-web-theme/lib/lumi.palette';
import typography from '@lumirental/lumi-web-theme/lib/lumi.typography';

const overrides = (dir) => {
  return {
    ...commonOverride,
    MuiCssBaseline: {
      styleOverrides: {
        html: {
          width: '100%',
          height: '100%',
          WebkitFontSmoothing: 'auto',
          WebkitTextSizeAdjust: '100%',
          TextSizeAdjust: '100%',
          WebkitTapHighlightColor: 'rgba(0, 0, 0, 0)',
        },
        body: {
          width: '100%',
          height: '100%',
          margin: 0,
          backgroundColor: palette.common.lightBodyBackground,
          fontFamily:
            dir === DIR.LTR
              ? FONT_FAMILY.FontFamilyEn
              : FONT_FAMILY.FontFamilyAr,
          textAlign: 'left',
          '& > #__next': {
            height: '100%',
          },
          '& .grecaptcha-badge': {
            display: 'none !important',
          },
        },
      },
    },
    MuiContainer: {
      styleOverrides: {
        root: {
          '&.MuiContainer-maxWidthLg': {
            maxWidth: `1200px !important`,
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiFormLabel-root': {
            color: palette.text.placeholder,
            fontSize: typography.fontSize15,
            lineHeight: 1.5,
            left: 12,
            top: -5,
          },
          '& .MuiInput-underline': {
            '&:after, &:before': {
              display: 'none',
            },
            '& .MuiInputBase-input': {
              border: `1px solid ${palette.common.grey100}`,
              borderRadius: 4,
            },
            '&.Mui-focused .MuiInputBase-input': {
              padding: '15px 13px',
              borderWidth: 2,
              borderColor: palette.common.blue300,
            },
          },
          '& .MuiOutlinedInput-root': {
            border: `1px solid ${palette.common.grey100}`,

            '&.MuiOutlinedInput-adornedStart': {
              paddingLeft: 0,
            },
            '&.Mui-error fieldset, &.Mui-error:hover fieldset': {
              borderColor: palette.common.red400,
            },
            '& fieldset': {
              borderColor: palette.common.grey20,
            },
            '&:hover fieldset': {
              borderColor: palette.common.grey20,
            },
            '&.Mui-focused fieldset': {
              borderColor: palette.common.blue300,
            },
          },
          '& .Mui-disabled': {
            backgroundColor: palette.common.grey20,
            opacity: '0.8',
          },
          '& .MuiFormHelperText-root.Mui-error': {
            color: palette.common.red400,
            fontSize: 13,
            lineHeight: 1.2,
            fontWeight: 400,
            marginLeft: 0,
            marginTop: 8,
          },
          '& .MuiFormHelperText-root': {
            marginLeft: 0,
            marginTop: 8,
            fontSize: 13,
            fontWeight: 400,
          },
        },
      },
    },
  };
};
export default overrides;
