import differenceInYears from 'date-fns/differenceInYears';

/**
 * apply smooth scrolling to reach specific element.
 * @param {String} eleId
 */
export const manageSmoothScrolling = (eleId) => {
  const ref = document.querySelector(eleId);
  ref && ref.scrollIntoView({ behavior: 'smooth', block: 'start' });
};

/**
 * check date of birth difference from current year
 * @param {String} year
 * @param {String} month
 * @param {String} day
 */
export const checkDOB = (year, month, day) => {
  const birthDate = new Date(year, month, day);
  const currentDate = new Date();
  return differenceInYears(currentDate, birthDate);
};
