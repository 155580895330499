import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Image from 'next/legacy/image';
import Link from 'next/link';
import { useRouter } from 'next/router';

import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';

import { makeStyles } from 'tss-react/mui';

import LANGUAGES from '@lumirental/lumi-web-shared/lib/constants/lang/LANGUAGES';
import { useLanguageContext } from '@lumirental/lumi-web-shared/lib/contexts/Language.context';
import useAB from '@lumirental/lumi-web-shared/lib/hooks/useAB';
import getLocaleRoute from '@lumirental/lumi-web-shared/lib/utils/getLocaleRoute';
import changeLanguageTapped from '@lumirental/lumi-web-shared/lib/utils/gtm/changeLanguageTapped';
import profileTapped from '@lumirental/lumi-web-shared/lib/utils/gtm/profileTapped';
import signOutTapped from '@lumirental/lumi-web-shared/lib/utils/gtm/signOutTapped';
import navigateAndScrollToTop from '@lumirental/lumi-web-shared/lib/utils/lang/navigateAndScrollToTop';
import switchLanguagePath from '@lumirental/lumi-web-shared/lib/utils/lang/switchLanguagePath';

import { onTapEvent } from '@/utils/gtm';

import ActionDialog from '@/components/Dialogs/Action';

import NestedMenu from './NestedMenu';

import styles from './Header.style';

const useStyles = makeStyles()(styles);

const MENUS = {
  HOME: '',
  OFFERS: 'offers',
  LOCATIONS: 'locations',
  ABOUT_US: 'company/about',
  SERVICES: 'services',
  BOOKINGS: 'bookings',
  CONTACT_US: 'contact',
  ACCOUNT: 'account',
  LOGIN: 'profile',
  TERMS_AND_CONDITIONS: 'terms and conditions',
  IR: 'company/IR',
  UCS: 'car-sales',
  MOTORCYCLE: 'car-rental/motorcycles',
  BUS_RENTAL: 'bus-rental',
  MANAGE_PAYMENT: 'manage-payment',
  DIRECT_SALE: 'used-car',
  CAREERS: 'company/careers',
};
const nonBookingFlowRoutes = [
  '/',
  '/company/about',
  '/offers',
  '/services',
  '/locations',
  '/faq',
  '/terms',
  '/privacy',
  '/car-rental/motorcycles',
  '/bus-rental',
  '/used-car',
];

const MARKETING_DIALOG_STORAGE = 'isMarketingDialogSeen';

export default function Header({ isLoggedIn, setIsBookingFlow, logout }) {
  // set Initial state.
  const [anchorEl, setAnchorEl] = useState(null);
  const [showLogoutConfirmDialog, setShowLogoutConfirmDialog] = useState(false);

  const { t } = useTranslation();
  const router = useRouter();
  const { classes } = useStyles();

  const { isArabic, language } = useLanguageContext();

  const isSaveCardForFuturePaymentEnable = useAB('save_card_flag');

  const { pathname } = router;
  const currentPath = router.route;
  const currentPage = pathname.substring(1);
  const isNonBookingFlow = nonBookingFlowRoutes.indexOf(currentPath) !== -1;

  const langBtnText = isArabic
    ? t('English_language_switch_button')
    : t('Arabic_language_switch_button');

  const dataLang = isArabic ? LANGUAGES.EN : LANGUAGES.AR;

  const isMenuOpen = Boolean(anchorEl);

  const handleLangChange = (evt) => {
    // send GTM event when language link tapped.
    changeLanguageTapped(isLoggedIn);

    // const label = evt.currentTarget.innerText;
    const setLang = evt.currentTarget.dataset.lang;
    const newUrl = switchLanguagePath(setLang);
    global.location.href = newUrl; // update the new url in browser
  };

  const handleMenuItemClick = (selectedMenu) => {
    // when menu item is clicked
    // it navigates via normal <a> links
    // event callback is called only to send GTM event
    let eventName = '';
    if (selectedMenu === MENUS.HOME) {
      eventName = 'home_tapped';
    }

    if (selectedMenu === MENUS.ABOUT_US) {
      eventName = 'about_us_tapped';
    }
    if (selectedMenu === MENUS.CAREERS) {
      eventName = 'careers_tapped';
    }

    if (selectedMenu === MENUS.OFFERS) {
      eventName = 'offers_tapped';
    }

    if (selectedMenu === MENUS.MOTORCYCLE) {
      eventName = 'motorcycle_tapped';
    }

    if (selectedMenu === MENUS.LOCATIONS) {
      eventName = 'location_tapped';
    }

    if (selectedMenu === MENUS.SERVICES) {
      eventName = 'services_tapped';
    }
    if (selectedMenu === MENUS.IR) {
      eventName = 'ir_tapped';
    }

    if (selectedMenu === MENUS.BOOKINGS) {
      eventName = 'bookings_tapped';
      handleMenuClose();
    }

    if (selectedMenu === MENUS.MANAGE_PAYMENT) {
      eventName = 'manage_payment_tapped';
      handleMenuClose();
    }

    if (selectedMenu === MENUS.UCS) {
      eventName = 'ucs_tapped';
    }

    if (selectedMenu === MENUS.LOGIN) {
      if (isLoggedIn) {
        // send GTM event when profile tapped.
        profileTapped(isLoggedIn);
      } else {
        setIsBookingFlow(false);
        eventName = 'sign_in_tapped';
      }
      handleMenuClose();
    }

    if (eventName) {
      // send GA event
      onTapEvent('Home', eventName);
    }
  };

  const checkActiveMenu = (page) => {
    return `${classes.itemLink} ${currentPage === page ? 'active' : ''}`;
  };

  const checkActiveDropdownMenu = (page) => {
    return `${classes.dropdownItem} ${currentPage === page ? 'active' : ''}`;
  };

  const checkMyAccountActiveMenu = () => {
    let active = '';
    if (currentPage === MENUS.LOGIN || currentPage === MENUS.BOOKINGS) {
      active = 'active';
    }
    return `${classes.itemLink} ${active}`;
  };

  const handleLogoutSuccess = () => {
    handleHideLogoutDialog();

    // Perform localStorage action
    localStorage.removeItem(MARKETING_DIALOG_STORAGE);

    // navigate to home page
    // navigateAndScrollToTop(router, '/');
    window.location.href = '/';
  };

  const handleLogoutError = () => {
    // probably show error message
    console.log('logout error');
  };

  const handleLogout = () => {
    logout(handleLogoutSuccess, handleLogoutError);

    // send GTM event when signout tapped.
    signOutTapped();

    window.Moengage?.destroy_session();
  };

  const handleShowLogoutDialog = () => {
    setShowLogoutConfirmDialog(true);
    handleMenuClose();
  };
  const handleHideLogoutDialog = () => {
    setShowLogoutConfirmDialog(false);
    handleMenuClose();
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  if (currentPage.includes('IR')) {
    return null;
  }

  const menuItems = [
    {
      label: t('ucs_header'),
      href: getLocaleRoute(language, MENUS.UCS),
    },
    {
      label: t('car_for_sale'),
      href: getLocaleRoute(language, MENUS.DIRECT_SALE),
    },
  ];

  return (
    <>
      <div id="headerBlock" className={classes.header}>
        <Container>
          <Grid container direction="row" justifyContent="space-between">
            <Grid item>
              <div className={classes.logoWrapper}>
                <a
                  alt="Lumi Logo"
                  title="Lumi Logo"
                  className={classes.logo}
                  href={getLocaleRoute(language, '')}
                >
                  <Image
                    src="/images/svg/lumi-logo.svg"
                    alt="Lumi Logo"
                    width={100}
                    height={43}
                    quality={100}
                    priority
                  />
                </a>
              </div>
            </Grid>
            <Grid item>
              <List component="nav" className={classes.list}>
                <ListItem className={classes.listItem}>
                  <a
                    alt={t('home')}
                    title={t('home')}
                    href={getLocaleRoute(language, '')}
                  >
                    <Typography
                      className={checkActiveMenu(MENUS.HOME)}
                      onClick={() => handleMenuItemClick(MENUS.HOME)}
                    >
                      {t('home')}
                    </Typography>
                  </a>
                </ListItem>
                {isNonBookingFlow && (
                  <>
                    <ListItem className={classes.listItem}>
                      <Link passHref href={getLocaleRoute(language, MENUS.IR)}>
                        <Typography
                          alt={t('investor-relations')}
                          title={t('investor-relations')}
                          className={checkActiveMenu(MENUS.IR)}
                          onClick={() => handleMenuItemClick(MENUS.IR)}
                        >
                          {t('investor-relations')}
                        </Typography>
                      </Link>
                    </ListItem>
                    <ListItem className={classes.listItem}>
                      <Link
                        passHref
                        href={getLocaleRoute(language, MENUS.ABOUT_US)}
                      >
                        <Typography
                          alt={t('about_lumi')}
                          title={t('about_lumi')}
                          className={checkActiveMenu(MENUS.ABOUT_US)}
                          onClick={() => handleMenuItemClick(MENUS.ABOUT_US)}
                        >
                          {t('about_lumi')}
                        </Typography>
                      </Link>
                    </ListItem>
                    <ListItem className={classes.listItem}>
                      <Link
                        passHref
                        href={getLocaleRoute(language, MENUS.OFFERS)}
                      >
                        <Typography
                          alt={t('Offers')}
                          title={t('Offers')}
                          className={checkActiveMenu(MENUS.OFFERS)}
                          onClick={() => handleMenuItemClick(MENUS.OFFERS)}
                        >
                          {t('Offers')}
                        </Typography>
                      </Link>
                    </ListItem>
                    <ListItem className={classes.listItem}>
                      <Link
                        passHref
                        href={getLocaleRoute(language, MENUS.MOTORCYCLE)}
                      >
                        <Typography
                          alt={t('motorcycles')}
                          title={t('motorcycles')}
                          className={checkActiveMenu(MENUS.MOTORCYCLE)}
                          onClick={() => handleMenuItemClick(MENUS.MOTORCYCLE)}
                        >
                          {t('motorcycles')}
                        </Typography>
                      </Link>
                    </ListItem>
                    <ListItem className={classes.listItem}>
                      {/* <Link
                        passHref
                        href={getLocaleRoute(language, MENUS.BUS_RENTAL)}
                      >
                        <Typography
                          alt={t('bus_rental_nav_item')}
                          title={t('bus_rental_nav_item')}
                          className={checkActiveMenu(MENUS.BUS_RENTAL)}
                          onClick={() => handleMenuItemClick(MENUS.BUS_RENTAL)}
                        >
                          {t('bus_rental_nav_item')}
                        </Typography>
                      </Link> */}
                      <a
                        alt={t('bus_rental_nav_item')}
                        title={t('bus_rental_nav_item')}
                        className={checkActiveMenu(MENUS.BUS_RENTAL)}
                        onClick={() => handleMenuItemClick(MENUS.BUS_RENTAL)}
                        href={getLocaleRoute(language, MENUS.BUS_RENTAL)}
                      >
                        {t('bus_rental_nav_item')}
                      </a>
                    </ListItem>
                    <ListItem className={classes.listItem}>
                      <Link
                        passHref
                        href={getLocaleRoute(language, MENUS.LOCATIONS)}
                      >
                        <Typography
                          alt={t('Locations')}
                          title={t('Locations')}
                          className={checkActiveMenu(MENUS.LOCATIONS)}
                          onClick={() => handleMenuItemClick(MENUS.LOCATIONS)}
                        >
                          {t('Locations')}
                        </Typography>
                      </Link>
                    </ListItem>
                    <ListItem className={classes.listItem}>
                      {/* <Link passHref href={getLocaleRoute(language, MENUS.UCS)}>
                        <Typography
                          alt={t('ucs_header')}
                          title={t('ucs_header')}
                          className={checkActiveMenu(MENUS.UCS)}
                          onClick={() => handleMenuItemClick(MENUS.UCS)}
                        >
                          {t('ucs_header')}
                        </Typography>
                      </Link> */}
                      <NestedMenu
                        buttonLabel={t('Used Car Sale')}
                        className={checkActiveMenu(MENUS.DIRECT_SALE)}
                        menuItems={menuItems}
                      />
                    </ListItem>
                    {/* <ListItem className={classes.listItem}>
                      <Link
                        passHref
                        href={getLocaleRoute(language, MENUS.DIRECT_SALE)}
                      >
                        <Typography
                          alt={t('direct_sale_nav_item')}
                          title={t('direct_sale_nav_item')}
                          className={checkActiveMenu(MENUS.DIRECT_SALE)}
                          onClick={() => handleMenuItemClick(MENUS.DIRECT_SALE)}
                        >
                          {t('Direct Sale')}
                        </Typography>
                      </Link>
                    </ListItem> */}
                    {/*  Comment Service menu for now */}
                    {/* <ListItem className={classes.listItem}>
                      <Link
                        passHref
                        href={getLocaleRoute(language, MENUS.SERVICES)}
                      >
                        <Typography
                          
                          alt={t('Services')}
                          title={t('Services')}
                          className={checkActiveMenu(MENUS.SERVICES)}
                          onClick={() => handleMenuItemClick(MENUS.SERVICES)}
                        >
                          {t('Services')}
                        </Typography>
                      </Link>
                    </ListItem> */}
                  </>
                )}
                {!isLoggedIn && (
                  <ListItem className={classes.listItem}>
                    <Link
                      passHref
                      href={getLocaleRoute(language, MENUS.ACCOUNT)}
                    >
                      <Typography
                        alt={t('signup_web')}
                        title={t('signup_web')}
                        className={`${checkActiveMenu(MENUS.ACCOUNT)}${
                          classes.signInLink
                        }`}
                        onClick={() => handleMenuItemClick(MENUS.LOGIN)}
                      >
                        {t('signup_web')}
                      </Typography>
                    </Link>
                  </ListItem>
                )}
                {isLoggedIn && (
                  <>
                    <ListItem className={classes.listItem}>
                      <Typography
                        id="demo-positioned-button"
                        aria-controls={
                          isMenuOpen ? 'demo-positioned-menu' : undefined
                        }
                        aria-haspopup="true"
                        aria-expanded={isMenuOpen ? 'true' : undefined}
                        className={`${checkMyAccountActiveMenu()}${
                          classes.signInLink
                        }`}
                        onClick={(event) => handleMenuOpen(event)}
                      >
                        {t('my_account_web')}
                      </Typography>
                    </ListItem>
                    <Menu
                      id="demo-positioned-menu"
                      aria-labelledby="demo-positioned-button"
                      anchorEl={anchorEl}
                      open={isMenuOpen}
                      onClose={() => handleMenuClose()}
                      classes={{
                        paper: classes.menu,
                        list: classes.dropdownList,
                      }}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                    >
                      <MenuItem
                        className={checkActiveDropdownMenu(MENUS.LOGIN)}
                        onClick={() => handleMenuItemClick(MENUS.LOGIN)}
                      >
                        <Link
                          passHref
                          href={getLocaleRoute(language, MENUS.LOGIN)}
                        >
                          <Typography
                            alt={t('profile')}
                            title={t('profile')}
                            className={classes.dropdonwItemText}
                          >
                            <span className={classes.imgIcon}>
                              <Image
                                src="/images/svg/my_profile_icon.svg"
                                alt="Account"
                                width={20}
                                height={20}
                              />
                            </span>
                            {t('profile')}
                          </Typography>
                        </Link>
                      </MenuItem>
                      <MenuItem
                        className={checkActiveDropdownMenu(MENUS.BOOKINGS)}
                        onClick={() => handleMenuItemClick(MENUS.BOOKINGS)}
                      >
                        <Link
                          passHref
                          href={getLocaleRoute(language, MENUS.BOOKINGS)}
                        >
                          <Typography
                            alt={t('my_bookings_web')}
                            title={t('my_bookings_web')}
                            className={classes.dropdonwItemText}
                          >
                            <span className={classes.imgIcon}>
                              <Image
                                src="/images/svg/my_bookings_icon.svg"
                                alt="My Bookings"
                                width={20}
                                height={20}
                              />
                            </span>
                            {t('my_bookings_web')}
                          </Typography>
                        </Link>
                      </MenuItem>
                      {isSaveCardForFuturePaymentEnable && (
                        <MenuItem
                          className={checkActiveDropdownMenu(
                            MENUS.MANAGE_PAYMENT,
                          )}
                          onClick={() =>
                            handleMenuItemClick(MENUS.MANAGE_PAYMENT)
                          }
                        >
                          <Link
                            passHref
                            href={getLocaleRoute(
                              language,
                              MENUS.MANAGE_PAYMENT,
                            )}
                            alt={t('menu_item_manage_payment')}
                            title={t('menu_item_manage_payment')}
                          >
                            <Typography className={classes.dropdonwItemText}>
                              <span className={classes.imgIcon}>
                                <Image
                                  src="https://cdn.lumirental.com/desktop/images/svg/manage-payment-icon.svg"
                                  alt="Manage Payments Icon"
                                  width={20}
                                  height={20}
                                />
                              </span>
                              {t('menu_item_manage_payment')}
                            </Typography>
                          </Link>
                        </MenuItem>
                      )}
                      <MenuItem
                        className={classes.dropdownItem}
                        onClick={handleShowLogoutDialog}
                        data-testid="logoutButton"
                      >
                        <Typography className={classes.dropdonwItemText}>
                          <span className={classes.imgIcon}>
                            <Image
                              src="/images/svg/signout_icon.svg"
                              alt="Sign out"
                              width={20}
                              height={20}
                            />
                          </span>
                          {t('logout')}
                        </Typography>
                      </MenuItem>
                    </Menu>
                  </>
                )}
                <ListItem className={classes.listItem}>
                  <Typography
                    data-lang={dataLang}
                    className={classes.itemLink}
                    onClick={handleLangChange}
                  >
                    {langBtnText}
                  </Typography>
                </ListItem>
              </List>
            </Grid>
          </Grid>
        </Container>
      </div>
      <ActionDialog
        openDialog={showLogoutConfirmDialog}
        infoMessage={t('are_you_sure_logout')}
        primaryCtaTitle={t('log_out')}
        secondaryCtaTitle={t('close')}
        handlePrimaryCtaAction={handleLogout}
        handleSecondaryCtaAction={handleHideLogoutDialog}
      />
    </>
  );
}
