const styles = () => ({
  wrapper: {
    marginBottom: 12,
  },
  divider: {
    marginTop: 12,
  },
});

export default styles;
