import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Slider from 'react-slick';
import _get from 'lodash/get';
import { toJS } from 'mobx';

import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Snackbar from '@mui/material/Snackbar';
import Typography from '@mui/material/Typography';

import { makeStyles } from 'tss-react/mui';

import { useLanguageContext } from '@lumirental/lumi-web-shared/lib/contexts/Language.context';

import { cardComponentFactory, cardDataFactory } from '@/utils/cardFactory';

import LeftArrow from '@/components/SlickArrows/LeftArrow';
import RightArrow from '@/components/SlickArrows/RightArrow';

import styles from '@/blocks/Carousel/CarouselBlock.style';

const useStyles = makeStyles()(styles);

export default function CarouselBlock({ data, language }) {
  const allData = toJS(data);
  const arraysSumCount =
    allData?.[0]?.length + allData?.[1]?.length + allData?.[2]?.length;
  const otherCards = allData.filter((el) => el?.id).length;
  const count = arraysSumCount + otherCards;

  const { isArabic } = useLanguageContext();

  // state for snackbar message when code copied
  const [openSnackBar, setOpenSnackBar] = useState(false);

  const { classes } = useStyles();
  const { t } = useTranslation();

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: count > 3 ? 3 : count,
    slidesToScroll: 1,
    rtl: isArabic,
    autoplay: true,
    autoplaySpeed: 2000,
    nextArrow: <RightArrow aria-label="Next" language={language} />,
    prevArrow: <LeftArrow aria-label="Previous" language={language} />,
    responsive: [
      {
        breakpoint: 1260,
        settings: {
          slidesToShow: count > 2 ? 2 : count,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const showSnackbar = () => {
    setOpenSnackBar(true);
  };

  const handleSnackBarClose = (evt, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackBar(false);
  };

  /**
   * Returns a React component for the offers card
   * @param {Object} smallItem
   * @param {number} index
   * @returns {Component}
   */
  const getCardComponent = (smallItem, index) => {
    const cardType = _get(smallItem, 'cardType', '');
    const formattedData = cardDataFactory(cardType, smallItem);
    const cardComponent = _get(cardComponentFactory, `${cardType}.comp`, null);
    const key = `${index}_${cardType}`;
    if (cardComponent) {
      return (
        <ListItem key={key} className={classes.slideItem}>
          {cardComponent(formattedData, language, showSnackbar)}
        </ListItem>
      );
    }
    return null;
  };

  return (
    <>
      <div className={classes.wrapper} id="whats_hot">
        <Container>
          <Grid container>
            <Grid item sm={12}>
              <div className={classes.titleWrapper}>
                <Typography variant="h4" className={classes.title}>
                  {t('What’s_hot')}
                </Typography>
                <Typography className={classes.subTitle}>
                  {t('Dont_wait_for')}
                </Typography>
              </div>
              <List className={classes.sliderWrapper}>
                <Slider {...settings}>
                  {data?.map((item) => {
                    if (item) {
                      // if an array with data comes from strapi
                      // eg. news card, offers card
                      if (item.length > 0) {
                        return item.map((smallItem, index) =>
                          getCardComponent(smallItem, index),
                        );
                      }
                      // if an object type comes from strapi
                      // eg. application card
                      if (item.length === undefined) {
                        return getCardComponent(item, 0);
                      }
                      // if an empty array comes from strapi
                      // eg. no offers
                      if (item.length === 0) {
                        return null;
                      }
                    }
                    // if a null comes from strapi
                    // e. no application card
                    return null;
                  })}
                </Slider>
              </List>
            </Grid>
          </Grid>
        </Container>
      </div>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={openSnackBar}
        onClose={handleSnackBarClose}
        autoHideDuration={2000}
        message={t('copy_code_successfully')}
        data-testid="copyCodeMessage"
      />
    </>
  );
}
