import React from 'react';
import Image from 'next/image';
import _get from 'lodash/get';
import PropTypes from 'prop-types';

import ButtonBase from '@mui/material/ButtonBase';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { makeStyles } from 'tss-react/mui';

import styles from './RecentSearchList.style';

const useStyles = makeStyles()(styles);

function RecentSearchList({ branches, handleSelectItem }) {
  const { classes } = useStyles();

  return (
    <Grid container className={classes.wrapper}>
      {branches &&
        branches.map((branch) => {
          const testId = `recentSearchBranch_${branch.id}`;
          const branchName = _get(branch, 'name', '');
          const cityName = _get(branch, 'cityName', '');
          const branchCityText = `${branchName}, ${cityName}`;
          return (
            <ButtonBase
              key={branch.id}
              className={classes.cardWrapper}
              data-testid={testId}
              onClick={() => handleSelectItem(branch)}
            >
              <Typography className={classes.title}>
                <span className={classes.clockIcon}>
                  <Image
                    height={24}
                    width={24}
                    src="/images/svg/clock-counter.svg"
                    alt="Clock Counter"
                  />
                </span>
                {branchCityText}
              </Typography>
            </ButtonBase>
          );
        })}
    </Grid>
  );
}

export default React.memo(RecentSearchList);

RecentSearchList.propTypes = {
  branches: PropTypes.array.isRequired,
  handleSelectItem: PropTypes.func,
};

RecentSearchList.defaultProps = {
  handleSelectItem: () => {
    // handle select here
  },
};
