const styles = (theme) => ({
  card: {
    backgroundColor: 'transparent',
    padding: 0,
    boxShadow: 'none',
    border: 'none',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  cardImg: {
    minHeight: 190,
    marginBottom: 18,
    width: '100%',
  },
  cardContent: {
    textAlign: 'center',
    padding: '0px 18px',
  },
  cardTitle: {
    color: theme.palette.common.blue400,
    fontSize: theme.typography.fontSize18,
    lineHeight: 1.5,
    fontWeight: theme.typography.fontWeightSemiBold,
    marginBottom: 4,
  },
  cardText: {
    display: 'inline-block',
    color: theme.palette.common.grey300,
    fontSize: theme.typography.fontSize15,
    fontWeight: theme.typography.fontWeightSemiBold,
    '& > span': {
      margin: '0 6px',
      color: theme.palette.common.blue400,
      fontSize: theme.typography.fontSize20,
    },
  },
  cardPriceWrapper: {
    direction: 'ltr',
  },
});

export default styles;
