import { useEffect, useState } from 'react';
import { autorun } from 'mobx';

function useDropOffBranchProps(store) {
  const [state, setState] = useState({});
  useEffect(() => {
    return autorun(() => {
      setState({
        isBranchLoading: store.appStore.searchPage.dropoffBranchStore.isLoading,
        isBranchSuccess: store.appStore.searchPage.dropoffBranchStore.isSuccess,
        hasFailed: store.appStore.searchPage.dropoffBranchStore.hasFailed,
        hasNoData: store.appStore.searchPage.dropoffBranchStore.hasNoData,
        language: store.appStore.searchPage.dropoffBranchStore.language,
        branchList: store.appStore.searchPage.dropoffBranchStore.branchList,
        cityIdHashMap:
          store.appStore.searchPage.dropoffBranchStore.cityIdHashMap,
        sortedCityIdHashMap:
          store.appStore.searchPage.dropoffBranchStore.sortedCityIdHashMap,
        sortedDeliveryCityIdHashMap:
          store.appStore.searchPage.dropoffBranchStore
            .sortedDeliveryCityIdHashMap,
        sortedPickUpCityIdHashMap:
          store.appStore.searchPage.dropoffBranchStore
            .sortedPickUpCityIdHashMap,
        count: store.appStore.searchPage.dropoffBranchStore.count,
        branchErrorData: store.appStore.searchPage.dropoffBranchStore.errorData,
        setInitialState:
          store.appStore.searchPage.dropoffBranchStore.setInitialState,
        searchType: store.appStore.searchPage.dropoffBranchStore.searchType,
      });
    });
  }, [store]);

  return state;
}

export default useDropOffBranchProps;
