// Custom SEO component for a page

'use client';

import React from 'react';
import Head from 'next/head';
import { useRouter } from 'next/router';

import LANGUAGES from '@lumirental/lumi-web-shared/lib/constants/lang/LANGUAGES';
import { useLanguageContext } from '@lumirental/lumi-web-shared/lib/contexts/Language.context';

export default function SEOTitle({
  pageSEOMetaData,
  hideCanonical = false,
  routeValue = '',
}) {
  const { title, description } = pageSEOMetaData;
  const { pathname } = useRouter();

  const { language } = useLanguageContext();

  const formattedPathName = pathname.replace(/\[.+\]/, routeValue);

  return (
    <Head>
      <title>{title}</title>
      <meta name="description" content={description} />
      {!hideCanonical && (
        <link
          rel="canonical"
          href={`https://lumirental.com/${language}${formattedPathName}`}
        />
      )}

      {Object.entries(LANGUAGES)?.map(([key, lang]) => (
        <link
          key={key}
          rel="alternate"
          href={`https://lumirental.com/${lang}${formattedPathName}`}
          hrefLang={lang}
        />
      ))}

      <link
        rel="alternate"
        href={`https://lumirental.com/ar${formattedPathName}`}
        hrefLang="x-default"
      />
    </Head>
  );
}
