import React, { createRef, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import addMinutes from 'date-fns/addMinutes';
import format from 'date-fns/format';
import getDay from 'date-fns/getDay';
import getTime from 'date-fns/getTime';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';

import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Popover from '@mui/material/Popover';
import TextField from '@mui/material/TextField';

import { makeStyles } from 'tss-react/mui';

import DEFAULT_BRANCH_TIMING from '@lumirental/lumi-web-sdk/dist/constants/date/DEFAULT_BRANCH_TIMING';
import getKSADate from '@lumirental/lumi-web-sdk/dist/utils/store/getKSADate';
import CLOSED_BRANCH from '@lumirental/lumi-web-shared/lib/constants/app/CLOSED_BRANCH';
import TIME_PICKER_UPDATE_REASON from '@lumirental/lumi-web-shared/lib/constants/app/TIME_PICKER_UPDATE_REASON';
import DATE_TIME_PICKER from '@lumirental/lumi-web-shared/lib/constants/date/DATE_TIME_PICKER';
import { useLanguageContext } from '@lumirental/lumi-web-shared/lib/contexts/Language.context';
import useAB from '@lumirental/lumi-web-shared/lib/hooks/useAB';
import generateAvailableTimeSlots from '@lumirental/lumi-web-shared/lib/utils/date/generateAvailableTimeSlots';
import generateTimeSlots from '@lumirental/lumi-web-shared/lib/utils/date/generateTimeSlots';
import getArabicAmPM from '@lumirental/lumi-web-shared/lib/utils/date/getArabicAmPM';

import { DARK_CLASS } from '../../constants';

import styles from './TimePicker.style';

const useStyles = makeStyles()(styles);

export default function TimePicker({
  id,
  value,
  onDays,
  offDays,
  selectedDate,
  selectedTime,
  pickupTime,
  isSameDay,
  isBranchSelected,
  handleSelectTime,
  openTimer,
  outsideCloseTimer,
  isThemeChange,
  branchTiming,
  leadTimeMinutes,
  branchUpdated,
  isBranchNeedUpdate,
  isError = false,
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [availableSlots, setAvailableSlots] = useState([]);
  const [branchSlots, setBranchSlots] = useState([]);
  const [selectedPickerTime, setSelectedPickerTime] = useState([]);

  const textInputRef = createRef();

  const { classes } = useStyles();
  const { t } = useTranslation();

  const { isArabic } = useLanguageContext();

  const testId = `searchSelect${id}`;
  const isCalendarOpen = Boolean(anchorEl);
  const isPickupTimeOpen = id === 'pickupTime';

  const isSearchUpdateEnabled = useAB('auto_search_update_tag');

  // formatted Time
  const formattedTime = value && isArabic ? getArabicAmPM(value) : value;
  const formattedSelectedDate = format(selectedDate, 'MMMM dd, yyy'); // eg. 'April 12, 2021'
  const selectedWeekDay = getDay(selectedDate); // eg. 0, 1, 3 etc
  const isSelectedDayOff = offDays.indexOf(selectedWeekDay) !== -1;
  const selectedDayTiming = _get(
    onDays,
    `${selectedWeekDay}`,
    DEFAULT_BRANCH_TIMING,
  );
  const { start24, end24, is24hr } = selectedDayTiming;
  // console.log('** onDays: ', selectedWeekDay, selectedDayTiming);
  const isEmptyTimeSlots = _isEmpty(availableSlots) || isSelectedDayOff;

  const paperClass = `${classes.paper} ${isThemeChange ? DARK_CLASS : ''}`;
  const shakeClass = `${isError ? classes.shake : ''}`;
  const fieldLabelClass = `${classes.fieldLabel} ${shakeClass} ${
    isError ? 'error' : ''
  }`;

  useEffect(() => {
    if (openTimer) {
      textInputRef.current.click();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openTimer]);

  useEffect(() => {
    setSelectedPickerTime(formattedTime);
  }, [formattedTime]);

  useEffect(() => {
    if (isSearchUpdateEnabled && branchUpdated) {
      const { availableTimeSlots } = generateTime();
      // get english value always
      const formattedTime = value;

      const isTimeChangeNeeded =
        !!formattedTime &&
        !!availableTimeSlots.length &&
        !availableTimeSlots.includes(formattedTime);

      isBranchNeedUpdate(
        TIME_PICKER_UPDATE_REASON.TIME_UPDATED,
        isPickupTimeOpen,
        isTimeChangeNeeded,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [branchUpdated]);

  const getWithLeadTime = (branchOpenTime, formattedSelectedDate) => {
    // convert to ksa time zone
    const ksaDate = getKSADate(new Date());
    let openTime;

    // from now the next slot available is T + Lead time
    // for both pickup time and dropoff time picker
    // ** there can be scenario that branch opening time
    // is more than t+leadTime. for eg. Friday branch opens at 4:29pm and the leadTime = 4hrs
    // if you open app at 10AM in morning - T+LeadTime is 2PM which is less than opening time
    // so handle that situation
    // T + LeadTime
    console.log('leadTimeMinutes', leadTimeMinutes);
    const ksaLeadTime = addMinutes(ksaDate, leadTimeMinutes); // returns a date object
    // If no leadTime then dropOff is selected
    if (getTime(branchOpenTime) > getTime(ksaLeadTime) || !leadTimeMinutes) {
      openTime = branchOpenTime;
    } else {
      openTime = ksaLeadTime;
    }
    // return openTime;
    // if pickup and drop dates are same
    // then only for dropoff time picker
    if (isSameDay && !isPickupTimeOpen) {
      // 30 mins gap between pick and drop time
      // find pick up time and add 30 mins to it
      const formattedPickUpTime = new Date(
        `${formattedSelectedDate} ${pickupTime}`,
      ); // using today as fake date
      openTime = addMinutes(formattedPickUpTime, DATE_TIME_PICKER.MINS_APART); // returns a date object
    }
    return openTime;
  };

  // generate available open hours after adding lead time and 30min gap between pickup and dropoff
  const generateAvailableTime = (
    branchOpenTime,
    branchCloseTime,
    formattedSelectedDate,
  ) => {
    // add lead time and 30 min between pickup and dropoff
    const formattedOpenTime = getWithLeadTime(
      branchOpenTime,
      formattedSelectedDate,
    );
    const branchTimeSlots = generateTimeSlots(branchOpenTime, branchCloseTime);
    const availableTimeSlots = generateAvailableTimeSlots(
      branchTimeSlots,
      formattedSelectedDate,
      formattedOpenTime,
    );

    return {
      branchTimeSlots,
      availableTimeSlots,
    };
  };

  const generateTime = () => {
    let branchOpenTime = new Date(`${formattedSelectedDate} ${start24}`);
    let branchCloseTime = new Date(`${formattedSelectedDate} ${end24}`);
    let availableTime;

    const selectedDayTimeDay = branchTiming.filter((el) => {
      return el.daysLabels
        ? el.daysLabels.includes(selectedDayTiming?.day?.toLowerCase())
        : el.dayLabel.toLowerCase() === selectedDayTiming?.day?.toLowerCase();
    });

    const timeRanges = _get(selectedDayTimeDay[0], 'timeRanges', []); // get timeRanges

    // if its a 24 hr branch or no time range is available
    if (is24hr || !timeRanges.length) {
      if (is24hr) {
        const formattedOpenTime = new Date(
          `${formattedSelectedDate} ${DEFAULT_BRANCH_TIMING.start24}`,
        );
        const formattedCloseTime = new Date(
          `${formattedSelectedDate} ${DEFAULT_BRANCH_TIMING.end24}`,
        );
        branchOpenTime = formattedOpenTime;
        branchCloseTime = formattedCloseTime;
      }

      // Prepare branchTimeSlots And availableTimeSlots
      availableTime = generateAvailableTime(
        branchOpenTime,
        branchCloseTime,
        formattedSelectedDate,
      );
    } else {
      const timeSlots = timeRanges.map((time) => {
        const startEndTime = time.split('-');
        const selectedDayTimeRangesStart = startEndTime[0];
        const selectedDayTimeRangesEnd = startEndTime[1];
        const branchRangeTimeStart = new Date(
          `${formattedSelectedDate} ${selectedDayTimeRangesStart}`,
        );
        const branchRangeTimeEnd = new Date(
          `${formattedSelectedDate} ${selectedDayTimeRangesEnd}`,
        );

        const availableTimeRange = generateAvailableTime(
          branchRangeTimeStart,
          branchRangeTimeEnd,
          formattedSelectedDate,
        );

        return {
          branchRangeTimeStart,
          branchRangeTimeEnd,
          ...availableTimeRange,
        };
      });

      // Add closed text if break is available
      const availableTimeFromTimeSlots = timeSlots.reduce(
        (acc, el, idx, arr) => {
          // Do not add Closed text if its is the first range OR if the prev range does not have an available time slot
          if (idx === 0 || !arr[idx - 1]?.availableTimeSlots.length) {
            acc.availableTimeSlots.push(...el.availableTimeSlots);
            acc.branchTimeSlots.push(...el.branchTimeSlots);
            return acc;
          }

          el.availableTimeSlots.length
            ? acc.availableTimeSlots.push(
                `${CLOSED_BRANCH}_${idx}`,
                ...el.availableTimeSlots,
              ) // Add closed text between time ranges
            : acc.availableTimeSlots.push(...el.availableTimeSlots);
          acc.branchTimeSlots.push(...el.branchTimeSlots);
          return acc;
        },
        { branchTimeSlots: [], availableTimeSlots: [] },
      );

      // Prepare branchTimeSlots And availableTimeSlots
      availableTime = availableTimeFromTimeSlots;
    }

    // original branch time slots 30 mins apart
    // in 12 hrs format
    return availableTime;
  };

  const handleTimeOpen = (event) => {
    const { branchTimeSlots, availableTimeSlots } = generateTime();
    setBranchSlots(branchTimeSlots);
    setAvailableSlots(availableTimeSlots);
    setAnchorEl(event?.currentTarget);
  };

  const handleTimeClose = () => {
    setAnchorEl(null);
    outsideCloseTimer();
  };

  const handleTimeClick = (selectedTime) => {
    handleSelectTime(selectedTime);
    handleTimeClose();
  };

  const getOpenAndCloseTimeText = (time) => {
    let text = '';
    if (isBranchSelected && !is24hr) {
      if (time === branchSlots[0]) {
        text = t('opening_time');
      } else if (time === branchSlots[branchSlots.length - 1]) {
        text = t('closing_time');
      } else {
        text = '';
      }
    } else {
      text = '';
    }
    return text;
  };

  return (
    <div className={classes.wrapper}>
      <label htmlFor={id} className={fieldLabelClass}>
        {t('time')}
      </label>
      <TextField
        ref={textInputRef}
        fullWidth
        value={selectedPickerTime}
        id={id}
        onClick={handleTimeOpen}
        InputProps={{
          readOnly: true,
          classes: {
            root: shakeClass,
            input: classes.inputField,
          },
        }}
        data-testid={testId}
        error={isError}
      />
      <Popover
        id={id}
        open={isCalendarOpen}
        anchorEl={anchorEl}
        onClose={handleTimeClose}
        classes={{
          paper: paperClass,
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        {isEmptyTimeSlots ? (
          <p className={classes.noSlotsMsg}>{t('no_time_slots')}</p>
        ) : (
          <List component="nav" className={classes.list}>
            {availableSlots.map((time, index) => {
              let timeLabel = time && isArabic ? getArabicAmPM(time) : time;
              const isSelectedOption = time === selectedTime;
              const isClosed = time.includes(CLOSED_BRANCH);
              const itemTextClass = `${classes.itemText} ${
                isSelectedOption ? ' item-selected' : ''
              }${isClosed ? ' item-closed-text' : ''}`;
              const key = `${time}_${index}`;
              isClosed
                ? (timeLabel = `${t('branch_close_between_web')} ${
                    isArabic
                      ? getArabicAmPM(availableSlots[index - 1])
                      : availableSlots[index - 1]
                  } - ${
                    isArabic
                      ? getArabicAmPM(availableSlots[index + 1])
                      : availableSlots[index + 1]
                  }`)
                : null;
              return (
                <div key={key}>
                  <ListItem
                    button
                    className={`${classes.listItem} ${
                      isClosed ? classes.closedItem : ''
                    }`}
                    onClick={() => handleTimeClick(time)}
                    autoFocus={isSelectedOption}
                    disabled={isClosed}
                  >
                    <ListItemText
                      primary={timeLabel}
                      secondary={getOpenAndCloseTimeText(time)}
                      className={itemTextClass}
                    />
                  </ListItem>
                  <Divider />
                </div>
              );
            })}
          </List>
        )}
      </Popover>
    </div>
  );
}
