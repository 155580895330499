const styles = (theme) => ({
  card: {
    width: '100%',
    borderRadius: 8,
    direction: 'ltr',
    position: 'relative',
  },
  bgWrap: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  cardContent: {
    minHeight: 220,
    padding: 16,
    '&:last-child': {
      paddingBottom: 16,
    },
    '&:after': {
      content: '""',
      position: 'absolute',
      width: '100%',
      height: '100%',
      left: 0,
      bottom: 0,
      background: `linear-gradient(180deg, rgba(0, 0, 0, 0) 31.82%, ${theme.palette.common.black} 88.18%)`,
      opacity: 0.8,
    },
  },
  contentInner: {
    padding: 8,
  },
  cardTextWrap: {
    display: 'flex',
    alignItems: 'center',
    padding: '0 24px',
    position: 'absolute',
    bottom: 24,
    left: 0,
    width: '100%',
    zIndex: theme.zIndex.zIndex10,
  },
  cardText: {
    color: theme.palette.common.white,
    fontSize: theme.typography.fontSize15,
    lineHeight: 1.5,
    fontWeight: theme.typography.fontWeightSemiBold,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'normal',
    display: '-webkit-box !important',
    lineClamp: 3,
    boxOrient: 'vertical',
  },
  icon: {
    marginRight: 8,
  },
});

export default styles;
