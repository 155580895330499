import React from 'react';
import { useTranslation } from 'react-i18next';
import Image from 'next/legacy/image';
import Link from 'next/link';

import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Typography from '@mui/material/Typography';

import { makeStyles } from 'tss-react/mui';

import { useLanguageContext } from '@lumirental/lumi-web-shared/lib/contexts/Language.context';
import getLocaleRoute from '@lumirental/lumi-web-shared/lib/utils/getLocaleRoute';
import faqsTapped from '@lumirental/lumi-web-shared/lib/utils/gtm/faqsTapped';
import privacyPolicyTapped from '@lumirental/lumi-web-shared/lib/utils/gtm/privacyPolicyTapped';
import profileTapped from '@lumirental/lumi-web-shared/lib/utils/gtm/profileTapped';

import { onTapEvent } from '@/utils/gtm';

import styles from './NavSection.style';

const useStyles = makeStyles()(styles);

const MENUS = {
  HOME: 'home',
  ABOUT_US: 'company/about',
  OFFERS: 'offers',
  VEHICLES: 'vehicles',
  LOCATIONS: 'locations',
  SERVICES: 'services',
  LOGIN: 'login',
  TERMS_CONDITION: 'terms',
  PRIVACY_POLICY: 'privacy',
  FAQ: 'faq',
  IR: 'company/IR',
  MOTORCYCLE: 'car-rental/motorcycles',
  CAREERS: 'company/careers',
};

export default function NavSection({ isLoggedIn, setIsBookingFlow }) {
  const { classes } = useStyles();
  const { t } = useTranslation();

  const { isArabic, language } = useLanguageContext();

  const baseAppImgSrc = '/images/png/';
  const androidAppImg = isArabic
    ? 'google-play-badge-ar.png'
    : 'google-play-badge-en.png';

  const iosAppImg = isArabic
    ? 'apple-store-badge-ar.png'
    : 'apple-store-badge-en.png';

  const androidAppImgSrc = `${baseAppImgSrc}${androidAppImg}`;
  const iosAppImgSrc = `${baseAppImgSrc}${iosAppImg}`;

  const authMenuItem = isLoggedIn ? t('profile') : t('login');
  const authMenuName = isLoggedIn ? 'profile' : 'account';

  const handleSocialClick = (evt) => {
    // send GTM event
    const label = evt.currentTarget.innerText;
    // send GA event
    onTapEvent('Social Media', `${label}_link_tapped`);
  };

  const handleMenuItemClick = (selectedMenu) => {
    // when menu item is clicked
    // it navigates via normal <a> links
    // event callback is called only to send GTM event
    let eventName = '';
    if (selectedMenu === MENUS.HOME) {
      eventName = 'home_tapped';
    }
    if (selectedMenu === MENUS.ABOUT_US) {
      eventName = 'about_us_tapped';
    }
    if (selectedMenu === MENUS.OFFERS) {
      eventName = 'offers_tapped';
    }
    if (selectedMenu === MENUS.VEHICLES) {
      eventName = 'vehicles_tapped';
    }
    if (selectedMenu === MENUS.MOTORCYCLE) {
      eventName = 'motorcycle_tapped';
    }
    if (selectedMenu === MENUS.LOCATIONS) {
      eventName = 'location_tapped';
    }
    if (selectedMenu === MENUS.SERVICES) {
      eventName = 'services_tapped';
    }
    if (selectedMenu === MENUS.IR) {
      eventName = 'ir_tapped';
    }
    if (selectedMenu === MENUS.CAREERS) {
      eventName = 'careers_tapped';
    }
    if (selectedMenu === MENUS.TERMS_CONDITION) {
      eventName = 'terms_and_conditions_tapped';
    }
    if (selectedMenu === MENUS.PRIVACY_POLICY) {
      // send GTM event when privacy-policy link tapped.
      privacyPolicyTapped(isLoggedIn);
    }
    if (selectedMenu === MENUS.FAQ) {
      // send GTM event when faqs link tapped.
      faqsTapped(isLoggedIn);
    }

    if (selectedMenu === MENUS.LOGIN) {
      if (isLoggedIn) {
        // send GTM event when profile tapped.
        profileTapped(isLoggedIn);
      } else {
        // to show/hide continue as guest button in login page
        setIsBookingFlow(false);
        eventName = 'sign_in_tapped';
      }
    }

    if (eventName) {
      // send GA event
      onTapEvent('Home', eventName);
    }
  };

  return (
    <div className={classes.footerMiddleWrapper}>
      <div className={classes.contentWrapper}>
        <Container>
          <Grid container>
            <Grid item xs={12} sm={12} md={5}>
              <div className={classes.footerMiddleLeft}>
                <div className={classes.footerLogoContent}>
                  <Link
                    href={getLocaleRoute(language, '')}
                    className={classes.footerLogoLink}
                    alt="Lumi Logo"
                    title="Lumi Logo"
                  >
                    <Image
                      src="/images/svg/lumi-logo.svg"
                      alt="Lumi Logo"
                      width={100}
                      height={43}
                    />
                  </Link>
                  <Typography className={classes.footerLogoText}>
                    {t('passionately_working_vision')}
                  </Typography>
                  <Typography className={classes.footerSubtitle}>
                    {t('Book_RIDE_effortlessly')}
                  </Typography>
                  <div className={classes.footerAppGroup}>
                    <a
                      target="_blank"
                      rel="nofollow noopener noreferrer"
                      href="https://play.google.com/store/apps/details?id=com.seera.lumiapp "
                    >
                      <Image
                        src={androidAppImgSrc}
                        alt="Lumi Android App"
                        width={136}
                        height={40}
                      />
                    </a>
                    <a
                      target="_blank"
                      rel="nofollow noopener noreferrer"
                      href="https://apps.apple.com/ae/app/lumi-car-rental/id1489446283"
                    >
                      <Image
                        src={iosAppImgSrc}
                        alt="Lumi IOS App"
                        width={120}
                        height={40}
                      />
                    </a>
                  </div>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={7}>
              <div className={classes.footerMiddleRight}>
                <div className={classes.navigation}>
                  <Typography variant="h5" className={classes.navigationTitle}>
                    {t('Site_map')}
                  </Typography>
                  <List className={classes.navigationList}>
                    <ListItem className={classes.navigationItem}>
                      <Link
                        passHref
                        href={getLocaleRoute(language, '')}
                        legacyBehavior
                      >
                        <Typography
                          component="a"
                          alt={t('home')}
                          title={t('home')}
                          className={classes.navigationLink}
                          onClick={() => handleMenuItemClick(MENUS.HOME)}
                        >
                          {t('home')}
                        </Typography>
                      </Link>
                    </ListItem>
                    <ListItem className={classes.navigationItem}>
                      <Link
                        passHref
                        href={getLocaleRoute(language, MENUS.ABOUT_US)}
                        legacyBehavior
                      >
                        <Typography
                          component="a"
                          alt={t('about_lumi')}
                          title={t('about_lumi')}
                          className={classes.navigationLink}
                          onClick={() => handleMenuItemClick(MENUS.ABOUT_US)}
                        >
                          {t('about_lumi')}
                        </Typography>
                      </Link>
                    </ListItem>
                    <ListItem className={classes.navigationItem}>
                      <Link
                        passHref
                        href={getLocaleRoute(language, MENUS.OFFERS)}
                        legacyBehavior
                      >
                        <Typography
                          component="a"
                          alt={t('Offers')}
                          title={t('Offers')}
                          className={classes.navigationLink}
                          onClick={() => handleMenuItemClick(MENUS.OFFERS)}
                        >
                          {t('Offers')}
                        </Typography>
                      </Link>
                    </ListItem>
                    <ListItem className={classes.navigationItem}>
                      <Link
                        passHref
                        href={getLocaleRoute(language, MENUS.MOTORCYCLE)}
                        legacyBehavior
                      >
                        <Typography
                          component="a"
                          alt={t('motorcycles')}
                          title={t('motorcycles')}
                          className={classes.navigationLink}
                          onClick={() => handleMenuItemClick(MENUS.MOTORCYCLE)}
                        >
                          {t('motorcycles')}
                        </Typography>
                      </Link>
                    </ListItem>
                    <ListItem className={classes.navigationItem}>
                      <Link
                        passHref
                        href={getLocaleRoute(language, MENUS.LOCATIONS)}
                        legacyBehavior
                      >
                        <Typography
                          component="a"
                          alt={t('Locations')}
                          title={t('Locations')}
                          className={classes.navigationLink}
                          onClick={() => handleMenuItemClick(MENUS.LOCATIONS)}
                        >
                          {t('Locations')}
                        </Typography>
                      </Link>
                    </ListItem>
                    {/* comment service menu for now. */}
                    {/* <ListItem className={classes.navigationItem}>
                      <Link
                        passHref
                        href={getLocaleRoute(language, MENUS.SERVICES)}
                      >
                        <Typography
                          component="a"
                          alt={t('Services')}
                          title={t('Services')}
                          className={classes.navigationLink}
                          onClick={() => handleMenuItemClick(MENUS.SERVICES)}
                        >
                          {t('Services')}
                        </Typography>
                      </Link>
                    </ListItem> */}
                    <ListItem className={classes.navigationItem}>
                      <Link
                        passHref
                        href={getLocaleRoute(language, MENUS.IR)}
                        legacyBehavior
                      >
                        <Typography
                          component="a"
                          alt={t('investor-relations')}
                          title={t('investor-relations')}
                          className={classes.navigationLink}
                          onClick={() => handleMenuItemClick(MENUS.IR)}
                        >
                          {t('investor-relations')}
                        </Typography>
                      </Link>
                    </ListItem>
                    <ListItem className={classes.navigationItem}>
                      <Link
                        passHref
                        href={getLocaleRoute(language, MENUS.CAREERS)}
                        legacyBehavior
                      >
                        <Typography
                          component="a"
                          alt={t('careers')}
                          title={t('careers')}
                          className={classes.navigationLink}
                          onClick={() => handleMenuItemClick(MENUS.CAREERS)}
                        >
                          {t('careers')}
                        </Typography>
                      </Link>
                    </ListItem>
                    <ListItem className={classes.navigationItem}>
                      <Link
                        passHref
                        href={getLocaleRoute(language, authMenuName)}
                        legacyBehavior
                      >
                        <Typography
                          component="a"
                          className={classes.navigationLink}
                          onClick={() => handleMenuItemClick(MENUS.LOGIN)}
                        >
                          {authMenuItem}
                        </Typography>
                      </Link>
                    </ListItem>
                  </List>
                </div>
                <div className={classes.navigation}>
                  <Typography variant="h5" className={classes.navigationTitle}>
                    {t('Legal')}
                  </Typography>
                  <List className={classes.navigationList}>
                    <ListItem className={classes.navigationItem}>
                      <Link
                        passHref
                        href={getLocaleRoute(language, MENUS.TERMS_CONDITION)}
                        legacyBehavior
                      >
                        <Typography
                          component="a"
                          alt={t('terms_and_conditions')}
                          title={t('terms_and_conditions')}
                          className={classes.navigationLink}
                          onClick={() =>
                            handleMenuItemClick(MENUS.TERMS_CONDITION)
                          }
                        >
                          {t('terms_and_conditions')}
                        </Typography>
                      </Link>
                    </ListItem>
                    <ListItem className={classes.navigationItem}>
                      <Link
                        passHref
                        href={getLocaleRoute(language, MENUS.PRIVACY_POLICY)}
                        legacyBehavior
                      >
                        <Typography
                          component="a"
                          alt={t('privacy_policy')}
                          title={t('privacy_policy')}
                          className={classes.navigationLink}
                          onClick={() =>
                            handleMenuItemClick(MENUS.PRIVACY_POLICY)
                          }
                        >
                          {t('privacy_policy')}
                        </Typography>
                      </Link>
                    </ListItem>
                  </List>
                </div>
                <div className={classes.navigation}>
                  <Typography variant="h5" className={classes.navigationTitle}>
                    {t('Support')}
                  </Typography>
                  <List className={classes.navigationList}>
                    <ListItem className={classes.navigationItem}>
                      <Link
                        passHref
                        href={getLocaleRoute(language, MENUS.FAQ)}
                        legacyBehavior
                      >
                        <Typography
                          component="a"
                          alt={t('faq')}
                          title={t('faq')}
                          className={classes.navigationLink}
                          onClick={() => handleMenuItemClick(MENUS.FAQ)}
                        >
                          {t('faq')}
                        </Typography>
                      </Link>
                    </ListItem>
                  </List>
                </div>

                <div className={classes.socialMedia}>
                  <Typography variant="h5" className={classes.socialMediaTitle}>
                    {t('Social_media')}
                  </Typography>
                  <List className={classes.socialMediaList}>
                    <ListItem className={classes.socialMediaItem}>
                      <div className={classes.socialMediaIcon}>
                        <Image
                          src="/images/svg/social-icons/facebook.svg"
                          alt="Lumi Facebook Page"
                          width={24}
                          height={24}
                        />
                      </div>
                      <Typography
                        component="a"
                        href="https://www.facebook.com/lumirentalcompany"
                        target="_blank"
                        rel="nofollow noopener noreferrer"
                        className={classes.socialMediaLink}
                        onClick={handleSocialClick}
                      >
                        {t('facebook')}
                      </Typography>
                    </ListItem>
                    <ListItem className={classes.socialMediaItem}>
                      <div className={classes.socialMediaIcon}>
                        <Image
                          src="/images/svg/social-icons/instagram.svg"
                          alt="Lumi Instagram Page"
                          width={24}
                          height={24}
                        />
                      </div>
                      <Typography
                        component="a"
                        href="https://www.instagram.com/lumirentalco/?hl=en"
                        target="_blank"
                        rel="nofollow noopener noreferrer"
                        className={classes.socialMediaLink}
                        onClick={handleSocialClick}
                      >
                        {t('instagram')}
                      </Typography>
                    </ListItem>
                    <ListItem className={classes.socialMediaItem}>
                      <div className={classes.socialMediaIcon}>
                        <Image
                          src="/images/svg/social-icons/linkdin.svg"
                          alt="Lumi Linkedin Page"
                          width={24}
                          height={24}
                        />
                      </div>
                      <Typography
                        component="a"
                        href="https://www.linkedin.com/company/lumi-rental"
                        target="_blank"
                        rel="nofollow noopener noreferrer"
                        className={classes.socialMediaLink}
                        onClick={handleSocialClick}
                      >
                        {t('linkdin')}
                      </Typography>
                    </ListItem>
                    <ListItem className={classes.socialMediaItem}>
                      <div className={classes.socialMediaIcon}>
                        <Image
                          src="/images/svg/social-icons/twitter.svg"
                          alt="Lumi Twitter Page"
                          width={24}
                          height={24}
                        />
                      </div>
                      <Typography
                        component="a"
                        href="https://twitter.com/lumirentalco"
                        target="_blank"
                        rel="nofollow noopener noreferrer"
                        className={classes.socialMediaLink}
                        onClick={handleSocialClick}
                      >
                        {t('twitter')}
                      </Typography>
                    </ListItem>
                  </List>
                </div>
              </div>
            </Grid>
          </Grid>
        </Container>
      </div>
    </div>
  );
}
