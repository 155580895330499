import { useEffect, useState } from 'react';
import { autorun } from 'mobx';

function useDropOffLocationProps(store) {
  const [state, setState] = useState({});
  useEffect(() => {
    return autorun(() => {
      setState({
        isLocationLoading:
          store.appStore.searchPage.dropoffLocationStore.isLoading,
        isLocationSuccess:
          store.appStore.searchPage.dropoffLocationStore.isSuccess,
        hasLocationFailed:
          store.appStore.searchPage.dropoffLocationStore.hasFailed,
        lat: store.appStore.searchPage.dropoffLocationStore.lat,
        long: store.appStore.searchPage.dropoffLocationStore.long,
        locationErrorData:
          store.appStore.searchPage.dropoffLocationStore.errorData,
        clearLocationProps:
          store.appStore.searchPage.dropoffLocationStore.clearLocationProps,
      });
    });
  }, [store]);

  return state;
}

export default useDropOffLocationProps;
