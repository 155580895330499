const styles = (theme) => ({
  listItem: {
    width: '100%',
    padding: '0px 8px',
  },
  label: {
    display: 'inline-block',
    color: theme.palette.text.primary,
    fontSize: theme.typography.fontSize12,
    lineHeight: 1.5,
    marginBottom: 20,
    textDecoration: 'none',
  },
});

export default styles;
