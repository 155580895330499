const styles = (theme) => ({
  listWrapper: {
    backgroundColor: theme.palette.common.white,
  },
  listItem: {
    padding: 0,
    marginBottom: 16,
    backgroundColor: theme.palette.common.white,
    '&:last-child': {
      marginBottom: 0,
    },
  },
  noData: {
    padding: 0,
    color: theme.palette.text.primary,
    fontSize: theme.typography.fontSize16,
    lineHeight: 1.5,
    paddingBottom: 12,
  },
  searchText: {
    color: theme.palette.text.primary,
    fontSize: theme.typography.fontSize16,
    lineHeight: 1.5,
    paddingBottom: 12,
  },
  divider: {
    marginTop: 12,
    marginBottom: 12,
  },
});

export default styles;
