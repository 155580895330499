const styles = (theme) => ({
  dialog: {
    width: 500,
    borderRadius: 8,
    overflow: 'visible',
  },
  mainWrapper: {
    position: 'relative',
    maxWidth: 440,
    margin: '0 auto',
    padding: '0px 48px',
    paddingTop: 80,
  },
  locationBadge: {
    position: 'absolute',
    left: '50%',
    top: -48,
    width: 96,
    height: 96,
    backgroundColor: theme.palette.secondary.main,
    borderRadius: '100%',
    textAlign: 'center',
    padding: '20px 15px',
    transform: 'translateX(-50%)',
    zIndex: theme.zIndex.zIndex10,
  },
  locationIcon: {
    width: 50,
  },
  contentWrapper: {
    textAlign: 'center',
  },
  title: {
    color: theme.palette.common.blue400,
    fontSize: theme.typography.fontSize15,
    lineHeight: 1.4,
    fontWeight: theme.typography.fontWeightSemiBold,
    marginBottom: 24,
  },
  textInfo: {
    color: theme.palette.text.primary,
    fontSize: theme.typography.fontSize13,
    fontWeight: theme.typography.fontWeightRegular,
    lineHeight: 1.5,
    marginBottom: 24,
  },
  actionWrapper: {
    textAlign: 'center',
    padding: '24px 0px',
  },
  proceedBtn: {
    marginBottom: 24,
    display: 'block',
    width: '100%',
  },
  chooseDiffBtn: {
    textTransform: 'none',
    color: theme.palette.secondary.main,
    fontSize: theme.typography.fontSize15,
  },
});

export default styles;
