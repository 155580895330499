import { keyframes } from 'tss-react';

/**
 * Animation properties defined here
 */

export const shakeHorizontal = keyframes` 
    0 %  { transform: translate(1px, 1px) },
    10%  { transform: translate(-1px, 0px) },
    20%  { transform: translate(-3px, 0px) },
    30%  { transform: translate(3px, 0px) },
    40%  { transform: translate(1px, 0px) },
    50%  { transform: translate(-1px, 0px) },
    60%  { transform: translate(-3px, 0px) },
    70%  { transform: translate(3px, 0px) },
    80%  { transform: translate(-1px, 0px) },
    100% { transform: translate(1px, 0px) }`;
