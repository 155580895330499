const styles = (theme) => ({
  footerTopWrapper: {
    padding: 20,
    borderBottom: `1px solid ${theme.palette.common.white}`,
    '@media(max-width: 767px)': {
      padding: '22px 0',
    },
  },
  footerTopContainer: {
    alignItems: 'center',
  },
  footerHelp: {
    '@media(max-width: 959px)': {
      marginBottom: 24,
    },
  },
  footerHelpTitle: {
    color: theme.palette.primary.main,
    fontSize: theme.typography.fontSize20,
    lineHeight: 1.4,
    fontWeight: theme.typography.fontWeightSemiBold,
    marginBottom: 4,
  },
  footerHelpText: {
    color: theme.palette.common.white,
    fontSize: theme.typography.fontSize15,
    lineHeight: 1.4,
  },
  contactUs: {
    display: 'flex',
    '@media(min-width: 960px)': {
      justifyContent: 'flex-end',
    },
    '@media(max-width: 959px)': {
      display: 'block',
      width: '100%',
    },
  },
  contactUsItem: {
    display: 'flex',
    alignItems: 'center',
    '&:first-child': {
      borderRight: `1px solid ${theme.palette.common.green400}`,
    },
    '&:not(:last-child)': {
      marginRight: 30,
      paddingRight: 30,
    },
    '@media(max-width: 959px)': {
      padding: 0,
      width: '100%',
      '&:first-child': {
        borderRight: 'none',
      },
      '&:not(:last-child)': {
        marginRight: 0,
        paddingRight: 0,
        marginBottom: 22,
      },
    },
  },
  contactUsIcon: {
    marginRight: 18,
    marginTop: 8,
  },
  contactUsText: {
    color: theme.palette.common.white,
    fontSize: theme.typography.fontSize15,
    lineHeight: 1,
    fontWeight: theme.typography.fontWeightBold,
  },
  phoneNoText: {
    marginLeft: 5,
    color: theme.palette.common.white,
    fontSize: theme.typography.fontSize15,
    lineHeight: 1,
    fontWeight: theme.typography.fontWeightBold,
  },
  arabicText: {
    direction: 'rtl',
    unicodeBidi: 'embed',
  },
});

export default styles;
