const styles = (theme) => ({
  wrapper: {
    padding: '24px 0px',
  },
  titleWrapper: {
    marginBottom: 24,
  },
  title: {
    color: theme.palette.common.blue400,
    fontSize: theme.typography.fontSize28,
    lineHeight: 1.3,
    fontWeight: theme.typography.fontWeightSemiBold,
    marginBottom: 4,
  },
  subTitle: {
    color: theme.palette.common.grey400,
    fontSize: theme.typography.fontSize15,
    lineHeight: 1.5,
    marginBottom: 8,
    '& > b': {
      fontWeight: theme.typography.fontWeightBold,
    },
  },
  mapWrapper: {
    position: 'relative',
    textAlign: 'center',
    width: '100%',
    minHeight: '35vw',
    '@media(min-width: 2060px)': {
      minHeight: '20vw',
    },
  },
  mapImg: {
    maxWidth: '1000px !important',
    objectFit: 'contain !important',
    width: '100%',
  },
});

export default styles;
