export const BRANCH_LIST_THRESHOLD = 3;
export const BRANCH_LIST_THRESHOLD_DESKTOP = 2;

export const BOOKING_LIST_THRESHOLD = 3;

export const MENUS = {
  HOME: 'home',
  VEHICLES: 'vehicles',
  LOCATIONS: 'locations',
  ABOUT_US: 'about',
  SERVICES: 'services',
  CONTACT_US: 'contact',
  LOGIN: 'login',
  TERMS_AND_CONDITIONS: 'terms and conditions',
};

export const BANNER_VIDEO_SRC_URL = {
  EN: 'https://www.youtube-nocookie.com/embed/8OqfSVJT1mA?autoplay=1&autohide=1&fs=1&rel=0&hd=1&wmode=transparent&enablejsapi=1&html5=1&controls=0&showinfo=0',
  AR: 'https://www.youtube.com/embed/OvdD1WZKQD0?autoplay=1&autohide=1&fs=1&rel=0&hd=1&wmode=transparent&enablejsapi=1&html5=1&controls=0&showinfo=0',
};

export const HOME_SUB_MENUS = {
  WHATS_NEW: 'whats_hot',
  WHY_LUMI: 'why_lumi',
  OUR_STEP: 'our_step',
  OUR_LOCATION: 'our_location',
  VIEW_APP: 'view_app',
};
