import React from 'react';
import Image from 'next/legacy/image';

import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';

import { makeStyles } from 'tss-react/mui';

import styles from './CardSection.style';

const useStyles = makeStyles()(styles);

export default function CardSection() {
  const { classes } = useStyles();
  return (
    <div className={classes.cardWrapper}>
      <Container>
        <Grid container>
          <Grid item xs={12} sm={12} md={12}>
            <div className={classes.cardList}>
              <div className={classes.cardItem}>
                <Image
                  src="/images/png/card-icons/mada.png"
                  alt="Lumi Payment Accepted by Mada Card"
                  width={56}
                  height={19}
                />
              </div>
              <div className={classes.cardItem}>
                <Image
                  src="/images/png/card-icons/mastercard.png"
                  alt="Lumi Payment Accepted by Mastercard"
                  width={34}
                  height={27}
                />
              </div>
              <div className={classes.cardItem}>
                <Image
                  src="/images/png/card-icons/visa.png"
                  alt="Lumi Payment Accepted by VISA Card"
                  width={48}
                  height={16}
                />
              </div>
              <div className={classes.cardItem}>
                <Image
                  src="/images/png/card-icons/american_express.png"
                  alt="Lumi Payment Accepted by American Express Card"
                  width={25}
                  height={26}
                />
              </div>
            </div>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}
