import React from 'react';

import { makeStyles } from 'tss-react/mui';

import CardSection from './components/CardSection';
import ContactUsSection from './components/ContactUsSection';
import Copyright from './components/Copyright';
import NavSection from './components/NavSection';

import styles from './Footer.style';

const useStyles = makeStyles()(styles);

export default function Footer({ isLoggedIn, setIsBookingFlow }) {
  const { classes } = useStyles();

  return (
    <>
      <div className={classes.wrapper} id="footerSec">
        <ContactUsSection />
        <NavSection
          isLoggedIn={isLoggedIn}
          setIsBookingFlow={setIsBookingFlow}
        />
        <CardSection />
        <Copyright />
      </div>
    </>
  );
}
