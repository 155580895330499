import { createTheme } from '@mui/material/styles';

import palette from '@lumirental/lumi-web-theme/lib/lumi.palette';
import typography from '@lumirental/lumi-web-theme/lib/lumi.typography';
import desktopTypography from '@lumirental/lumi-web-theme/lib/lumi.typography.desktop';
import zIndex from '@lumirental/lumi-web-theme/lib/lumi.zindex';

import overrides from '@/themes/lumi.overrides';
// import overridesTypography from '@/themes/lumi.typography';

const baseTheme = (direction) => {
  return {
    palette,
    typography: { ...typography, ...desktopTypography },
    spacing: 8,
    zIndex,
    components: overrides(direction),
  };
};

export function getTheme(direction) {
  return createTheme({
    direction,
    ...baseTheme(direction),
  });
}
