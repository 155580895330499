import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import _size from 'lodash/size';

import ButtonBase from '@mui/material/ButtonBase';
import Chip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';

import { makeStyles } from 'tss-react/mui';

import Accordion from '../../Accordion';
import BranchCard from '../../Cards/BranchCard';
import CarDeliveryCard from '../../Cards/CarDelivery';

import styles from './CityList.style';

const useStyles = makeStyles()(styles);

const CityList = React.memo(
  ({
    language,
    data,
    isSwitchOpen,
    getBranchesByCityId,
    handleSelectItem,
    isCarDelivery,
  }) => {
    const [expanded, setExpanded] = useState('');
    const [branches, setBranches] = useState([]);

    const { classes } = useStyles();
    const { t } = useTranslation();
    const wrapperClass = isCarDelivery
      ? `${classes.cardWrapper} ${classes.carDeliveryWrapper}`
      : classes.cardWrapper;

    const handleHeaderClick = (branches) => {
      setBranches(branches); // update local state
    };

    return (
      <div component="nav" className={classes.listWrapper}>
        <div className={classes.listItem}>
          <Typography variant="h3" className={classes.listTitle}>
            {t('cities_we_are_available_in')}
          </Typography>
        </div>
        <Divider />
        {data &&
          data.map((city) => {
            const branchesByCityId = getBranchesByCityId(city.id);
            const noOfBranch = _size(branchesByCityId);
            const branchText = `${noOfBranch} ${t('branches', {
              count: noOfBranch,
            })}`;

            const testId = `searchCityCardHeader_${city.id}`;
            return (
              <React.Fragment key={city.id}>
                <Accordion
                  value={city.name}
                  expanded={expanded}
                  setExpanded={setExpanded}
                  headerClickHandler={() => handleHeaderClick(branchesByCityId)}
                  isSwitchOpen={isSwitchOpen}
                  accordionHeaderClass={classes.accordionTitleWrapper}
                  header={
                    // eslint-disable-next-line react/jsx-wrap-multilines
                    <>
                      <div data-testid={testId}>
                        <div className={classes.content}>
                          <Typography variant="h5" className={classes.title}>
                            {city.name}
                          </Typography>
                          {isCarDelivery && (
                            <Chip
                              size="small"
                              label={t('free_car_delivery')}
                              className={classes.chip}
                            />
                          )}
                        </div>
                        <div>
                          <Typography
                            variant="body2"
                            className={classes.subTitle}
                          >
                            {branchText}
                          </Typography>
                        </div>
                      </div>
                    </>
                  }
                >
                  {branches &&
                    branches.map((branch) => {
                      const testId = `searchCityBranchCard_${branch.id}`;
                      const showCard = isCarDelivery ? (
                        <CarDeliveryCard
                          language={language}
                          branch={branch}
                          handleCarDelivery={() =>
                            handleSelectItem(branch, 'city')
                          }
                        />
                      ) : (
                        <BranchCard
                          language={language}
                          branch={branch}
                          isfromCityCenter
                        />
                      );
                      return (
                        <ButtonBase
                          key={branch.id}
                          className={wrapperClass}
                          data-testid={testId}
                          onClick={() => handleSelectItem(branch, 'city')}
                        >
                          {showCard}
                        </ButtonBase>
                      );
                    })}
                </Accordion>
                <Divider />
              </React.Fragment>
            );
          })}
      </div>
    );
  },
  (prevProps, nextProps) => {
    // v.imp - for city list not to render unncecessarily
    return prevProps.data === nextProps.data;
  },
);

export default CityList;
