import React from 'react';
import Head from 'next/head';

import { prefetchGetBranches } from '@lumirental/lumi-web-shared/lib/tanStackQuery/branch.queries';
import { prefetchGetCmsAirports } from '@lumirental/lumi-web-shared/lib/tanStackQuery/cms/airport.queries';
import { prefetchGetCmsCities } from '@lumirental/lumi-web-shared/lib/tanStackQuery/cms/cities.queries';
import { prefetchGetCmsCityBranches } from '@lumirental/lumi-web-shared/lib/tanStackQuery/cms/cityBranches.queries';
import { prefetchGetCmsHome } from '@lumirental/lumi-web-shared/lib/tanStackQuery/cms/home.queries';
import { prefetchGetGroupTariff } from '@lumirental/lumi-web-shared/lib/tanStackQuery/groupTariff.queries';
import { prefetchQueries } from '@lumirental/lumi-web-shared/lib/tanStackQuery/prefetchQueries';

import SEOTitle from '@/components/SeoTitle';
import SearchContainer from '@/containers/SearchContainer';

export async function getServerSideProps({ locale }) {
  const { dehydratedState } = await prefetchQueries([
    prefetchGetBranches(),
    prefetchGetCmsHome({ language: locale }),
    prefetchGetCmsAirports({ language: locale }),
    prefetchGetCmsCities({ language: locale }),
    prefetchGetCmsCityBranches({ language: locale }),
    prefetchGetGroupTariff(),
  ]);

  return {
    props: {
      dehydratedState,
    },
  };
}
export default function HomePage({ store }) {
  const { iosAppId } = store.appStore.config.data;

  const pageSEOMetaData = store.appStore.config.getPageSEOMetaData('home');

  return (
    <>
      <Head>
        <meta
          name="apple-itunes-app"
          content={`app-id=${iosAppId}, app-clip-bundle-id=com.seera.lumiapp.dev.Clip, app-clip-display=card`}
        />

        {/* Organization schema */}
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: `{"@context":"http://schema.org","@type":"Organization","name":"Lumi","url":"https://www.lumirental.com","logo":"https://lumirental.com/favicon-96x96.png","sameAs":["https://www.facebook.com/lumirentalcompany","https://www.instagram.com/lumirentalco/","https://twitter.com/lumirentalco","https://www.linkedin.com/company/lumi-rental/"]}`,
          }}
        />
      </Head>

      <SEOTitle pageSEOMetaData={pageSEOMetaData} />
      <SearchContainer store={store} />
    </>
  );
}
