const styles = (theme) => ({
  wrapper: {
    backgroundColor: theme.palette.common.white,
  },
  tab: {
    display: 'none',
    '&.active': {
      display: 'block',
    },
  },
});

export default styles;
