const styles = (theme) => ({
  wrapper: {
    padding: '24px 0px',
  },
  titleWrapper: {
    textAlign: 'center',
    marginBottom: 24,
  },
  title: {
    color: theme.palette.common.blue400,
    fontSize: theme.typography.fontSize28,
    lineHeight: 1.3,
    fontWeight: theme.typography.fontWeightSemiBold,
  },
  colorTitle: {
    color: theme.palette.common.blue400,
    fontSize: theme.typography.fontSize28,
    lineHeight: 1.3,
    fontWeight: theme.typography.fontWeightSemiBold,
  },
  stepItemWrapper: {
    padding: '8px 0',
    margin: '0 -12px',
  },
  stepItem: {
    padding: '0 12px',
  },
});

export default styles;
