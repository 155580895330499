import React from 'react';
import Image from 'next/legacy/image';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

import { makeStyles } from 'tss-react/mui';

import { useLanguageContext } from '@lumirental/lumi-web-shared/lib/contexts/Language.context';

import styles from '@/components/Cards/OurStep/OurStepCard.style';

const useStyles = makeStyles()(styles);

export default function OurStepCard({ title, content, imgSrc, count }) {
  const { classes } = useStyles();

  const { isArabic } = useLanguageContext();

  const imgIconClass = `${classes.cardIcon} ${
    imgSrc ? classes.avatarIcon : ''
  }`;
  const iconClass = `${classes.stepIcon} ${isArabic ? classes.inArabic : ''}`;
  const isMoreStep = count && count > 1;

  return (
    <Card className={classes.card}>
      <div className={imgIconClass} data-count={count}>
        {imgSrc && (
          <Image
            src={imgSrc}
            alt={title}
            width={32}
            height={32}
            quality={100}
          />
        )}
        {!imgSrc && <span>{count}</span>}
      </div>
      <CardContent className={classes.cardContent}>
        <Typography variant="h6" className={classes.cardTitle}>
          {title}
        </Typography>
        <Typography className={classes.cardText}>{content}</Typography>
      </CardContent>
      {isMoreStep && (
        <span className={iconClass}>
          <Image
            src="/images/svg/step-arrow.svg"
            alt="Lumi Step Icon"
            width={22}
            height={12}
            quality={100}
          />
        </span>
      )}
    </Card>
  );
}
