import React from 'react';
import { useTranslation } from 'react-i18next';

import Button from '@mui/material/Button';
import ButtonBase from '@mui/material/ButtonBase';
import Typography from '@mui/material/Typography';

import { makeStyles } from 'tss-react/mui';

import TAB_INDEX from '@lumirental/lumi-web-shared/lib/constants/form/TAB_INDEX';

import styles from './FindCarTab.style';

const useStyles = makeStyles()(styles);

function FindCarTab({
  activeTabIndex,
  handleActiveTab,
  showCloseBtn = false,
  handleCloseSearch = () => {},
}) {
  const { classes } = useStyles();
  const { t } = useTranslation();

  const checkActiveTab = (tabIndex) => {
    return tabIndex === activeTabIndex ? 'active' : '';
  };

  return (
    <>
      <div className={classes.tabWrapper}>
        <div className={classes.tabItem}>
          <ButtonBase
            className={`${classes.tabContent} ${checkActiveTab(
              TAB_INDEX.FIRST,
            )}`}
            onClick={() => handleActiveTab(TAB_INDEX.FIRST)}
            data-testid="searchTab0"
          >
            <Typography variant="subtitle1" className={classes.tabTitle}>
              {t('short_term')}
            </Typography>
            <Typography variant="subtitle2" className={classes.tabSubtitle}>
              {t('daily_/_weekly')}
            </Typography>
          </ButtonBase>
        </div>
        <div className={classes.tabItem}>
          <ButtonBase
            className={`${classes.tabContent} ${checkActiveTab(
              TAB_INDEX.SECOND,
            )}`}
            onClick={() => handleActiveTab(TAB_INDEX.SECOND)}
            data-testid="searchTab1"
          >
            <Typography variant="subtitle1" className={classes.tabTitle}>
              {t('long_term')}
            </Typography>
            <Typography variant="subtitle2" className={classes.tabSubtitle}>
              {t('30_days_or_longer')}
            </Typography>
          </ButtonBase>
        </div>
        {showCloseBtn && (
          <div className={classes.closeBtnWrapper}>
            <Button
              variant="text"
              className={classes.closeBtn}
              onClick={handleCloseSearch}
              data-testid="closeButton"
            >
              {t('close')}
            </Button>
          </div>
        )}
      </div>
    </>
  );
}

export default FindCarTab;
