import React from 'react';
import { useTranslation } from 'react-i18next';
import Image from 'next/legacy/image';

import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { makeStyles } from 'tss-react/mui';

import { useLanguageContext } from '@lumirental/lumi-web-shared/lib/contexts/Language.context';

import styles from './ContactUsSection.style';

const useStyles = makeStyles()(styles);

export default function ContactUsSection() {
  const { classes } = useStyles();
  const { t } = useTranslation();

  const { isArabic } = useLanguageContext();

  const phoneNoClass = `${classes.phoneNoText} ${
    isArabic ? classes.arabicText : ''
  }`;

  const tel = `tel:${t('Call_xx')}`;
  const email = t('Email_xx').split('-');
  const mailTo = `mailto:${email[1]}`;

  return (
    <div className={classes.footerTopWrapper}>
      <Container>
        <Grid container className={classes.footerTopContainer}>
          <Grid item xs={12} sm={12} md={3}>
            <div className={classes.footerHelp}>
              <Typography variant="h5" className={classes.footerHelpTitle}>
                {t('Looking_for_help?')}
              </Typography>
              <Typography className={classes.footerHelpText}>
                {t('Our_team_is_available')}
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={9}>
            <div className={classes.contactUs}>
              <div className={classes.contactUsItem}>
                <div className={classes.contactUsIcon}>
                  <Image
                    src="/images/svg/phone-icon.svg"
                    alt="Lumi Contact Phone"
                    width={24}
                    height={24}
                  />
                </div>
                <Typography className={classes.contactUsText}>
                  {t('call')}
                </Typography>
                <Typography className={phoneNoClass} component="a" href={tel}>
                  {t('Call_xx')}
                </Typography>
              </div>
              <div className={classes.contactUsItem}>
                <div className={classes.contactUsIcon}>
                  <Image
                    src="/images/svg/mail-icon.svg"
                    alt="Lumi Contact Email"
                    width={24}
                    height={24}
                  />
                </div>
                <Typography className={classes.contactUsText}>
                  {`${email[0]}-`}
                </Typography>
                <Typography
                  className={phoneNoClass}
                  component="a"
                  href={mailTo}
                >
                  {email[1]}
                </Typography>
              </div>
            </div>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}
