const styles = (theme) => ({
  tabBg: {
    backgroundColor: theme.palette.common.white,
    boxShadow: '0px 2px 4px rgba(36, 36, 36, 0.08)',
  },
  tab: {
    padding: '24px 0',
    color: theme.palette.common.grey300,
    fontSize: theme.typography.fontSize16,
    lineHeight: 1.5,
    fontWeight: theme.typography.fontWeightRegular,
    opacity: 1,
    minWidth: 'auto',
    marginRight: '8vw',
    textTransform: 'none',
    '&.Mui-selected': {
      color: theme.palette.common.blue400,
      fontWeight: theme.typography.fontWeightBold,
    },
    '@media(max-width: 767px)': {
      fontSize: theme.typography.fontSize14,
      '&:not(last-child)': {
        marginRight: 24,
      },
    },
  },
  indicator: {
    backgroundColor: theme.palette.common.blue400,
    height: 3,
  },
});

export default styles;
