import React from 'react';

import useDataFetch from '@lumirental/lumi-web-shared/lib/hooks/useDataFetch';
import apiError from '@lumirental/lumi-web-shared/lib/utils/gtm/apiError';

import FindBranchBlock from './components/FindBranch';

export default function SearchWidget({
  store,
  textInputRef = null,
  onSearchClick,
  showCloseBtn,
  handleCloseSearch,
  isThemeChange = false,
}) {
  /**
   * Handles city & branch api error from sdk store
   * @param {AxiosError} err
   */
  const errorCallback = (err) => {
    console.log('Error response in data fetch search widget:', err);

    // send GTM event when API give error.
    apiError(err);
  };
  // fetch city and branch api data
  useDataFetch(store, errorCallback);

  return (
    <>
      <FindBranchBlock
        store={store}
        textInputRef={textInputRef}
        onSearchClick={onSearchClick}
        showCloseBtn={showCloseBtn}
        handleCloseSearch={handleCloseSearch}
        isThemeChange={isThemeChange}
      />
    </>
  );
}
