import { initReactI18next } from 'react-i18next';
import i18next from 'i18next';

// import isProdEnv from '@lumirental/lumi-web-shared/lib/utils/isProdEnv';

/**
 * initialize i18next
 * @param {String} language
 * @param {Object} translations
 */
export function initI18n(language, translations) {
  i18next.use(initReactI18next).init({
    initImmediate: false,
    lng: language,
    debug: false, // show log message only for local dev
    compatibilityJSON: 'v3',
    resources: {
      [language]: {
        translation: translations,
      },
    },
    interpolation: {
      prefix: '{',
      suffix: '}',
    },
    react: {
      useSuspense: false,
    },
  });
}
