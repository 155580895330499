import { prefixer } from 'stylis';
import rtlPlugin from 'stylis-plugin-rtl';

import createCache from '@emotion/cache';

const createEmotionCache = (dir) => {
  return createCache({
    key: 'makestyle',
    // prepend: true,
    ...(dir === 'rtl' ? { stylisPlugins: [prefixer, rtlPlugin] } : null),
  });
};

export default createEmotionCache;
