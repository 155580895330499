const styles = (theme) => ({
  listWrapper: {
    backgroundColor: theme.palette.common.white,
    paddingTop: 12,
  },
  listItem: {
    paddingLeft: 0,
    paddingRight: 0,
    paddingBottom: 16,
  },
  listTitle: {
    color: theme.palette.common.blue400,
    fontSize: theme.typography.fontSize20,
    lineHeight: 1.3,
  },
  content: {
    display: 'flex',
    alignItems: 'center',
  },
  accordionTitleWrapper: {
    padding: 16,
    borderRadius: 8,
    '&:hover': {
      backgroundColor: theme.palette.common.blue50,
    },
  },
  title: {
    color: theme.palette.common.blue400,
    fontSize: theme.typography.fontSize18,
    lineHeight: 1.3,
    textAlign: 'left',
    marginBottom: 4,
  },
  subTitle: {
    color: theme.palette.common.grey300,
    fontSize: theme.typography.fontSize14,
    lineHeight: 1.5,
    textAlign: 'left',
  },
  chip: {
    backgroundColor: theme.palette.common.blue300,
    fontWeight: theme.typography.fontWeightBold,
    padding: 4,
    marginLeft: 8,
    '& > .MuiChip-label': {
      color: theme.palette.common.white,
      fontSize: theme.typography.fontSize14,
      lineHeight: 1.2,
    },
  },
  cardWrapper: {
    display: 'block',
    width: '100%',
    textAlign: 'left',
    fontFamily: 'inherit',
    borderRadius: 8,
    maxWidth: '348px !important',
    '&:hover': {
      backgroundColor: theme.palette.common.blue50,
    },
  },
  carDeliveryWrapper: {
    maxWidth: '350px !important',
  },
});

export default styles;
