import React from 'react';
import { useTranslation } from 'react-i18next';
import _isEmpty from 'lodash/isEmpty';

import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Typography from '@mui/material/Typography';

import { makeStyles } from 'tss-react/mui';

import BranchList from '../Lists/BranchList';

import styles from './BranchListWrapper.style';

const useStyles = makeStyles()(styles);

const BranchListWrapper = React.memo(
  ({
    language,
    data,
    handleSelectItem,
    searchValue,
    branchErrorMessage,
    showBranchList,
    isLocationSuccess,
    isBranchSuccess,
    isSwitchOpen,
    getBranchesByCityId,
    searchClicked,
  }) => {
    const { classes } = useStyles();
    const { t } = useTranslation();

    // drived value
    const hasBranch = !_isEmpty(data);
    const hasSearch = !!searchValue;
    const searchBranchInfo = t('Showing_all_results_for').replace(
      '%@',
      `${searchValue}`,
    );

    const isSearchResult =
      hasSearch && isBranchSuccess && hasBranch && searchClicked;

    if (branchErrorMessage) {
      return (
        <>
          <Typography className={classes.noData}>
            {branchErrorMessage}
          </Typography>
          <Divider
            classes={{
              root: classes.divider,
            }}
          />
        </>
      );
    }

    return showBranchList || isLocationSuccess ? (
      <>
        {isSearchResult && !isLocationSuccess && (
          <>
            <Typography className={classes.searchText}>
              {searchBranchInfo}
            </Typography>
            <Divider
              classes={{
                root: classes.divider,
              }}
            />
          </>
        )}
        {hasBranch && (
          <List className={classes.listWrapper}>
            {Object.keys(data).map((cityId) => {
              const branches = data[cityId];
              return (
                <React.Fragment key={cityId}>
                  <ListItem className={classes.listItem}>
                    <BranchList
                      language={language}
                      branches={branches}
                      handleSelectItem={handleSelectItem}
                      searchValue={searchValue}
                      isSwitchOpen={isSwitchOpen}
                      getBranchesByCityId={getBranchesByCityId}
                    />
                  </ListItem>
                </React.Fragment>
              );
            })}
          </List>
        )}
      </>
    ) : null;
  },
);

export default BranchListWrapper;
